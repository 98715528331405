import HomePage from "../pages/Home/HomePage";
import PurchasesPage from "../pages/Purchases/PurchasesPage";
import OrdersPage from "../pages/Orders/OrdersPage";
import ComplaintsPage from "../pages/Complaints/ComplaintsPage";
import CommissionsPage from "../pages/Commissions/CommissionsPage";
import ReportsPage from "../pages/Reports/ReportsPage";
import DepartmentsPage from "../pages/Departments/DepartmentsPage";
import EmployeesPage from "../pages/Employees/EmployeesPage";
import RulesPage from "../pages/Rules/RulesPage";
import JournalPage from "../pages/Journal/JournalPage";
import SettingsPage from "../pages/Settings/SettingsPage";
import ProfilePage from "../pages/Profile/ProfilePage";
import ProfileInterfacePage from "../pages/Profile/ProfileInterfacePage";
import ProfilePersonalPage from "../pages/Profile/ProfilePersonalPage";
import ProfilePasswordPage from "../pages/Profile/ProfilePasswordPage";
import NotFoundPage from "../pages/NotFoundPage";
import AccessDeniedPage from "../pages/AccessDeniedPage";
import LoginPage from "../pages/Login/LoginPage";
import SettingsPurchasesPage from "../pages/Settings/SettingsPurchasesPage";
import SettingsOrdersPage from "../pages/Settings/SettingsOrdersPage";
import SettingsSmtpPage from "../pages/Settings/SettingsSmtpPage";
import LogoutPage from "../pages/Logout/LogoutPage";
import SettingsEmployeesPage from "../pages/Settings/SettingsEmployeesPage";
import SettingsComplaintsPage from "../pages/Settings/SettingsComplaintsPage";


export const privateRoutes = [

    //Главная
    {path: '', element: <HomePage />, title: 'Главная'},

    //Закупки
    {path: 'purchases', element: <PurchasesPage />, title: 'Закупки'},

    //Приказы
    {path: 'orders', element: <OrdersPage />, title: 'Приказы'},

    //Жалобы
    {path: 'complaints', element: <ComplaintsPage />, title: 'Жалобы'},

    //Комиссия
    {path: 'commissions', element: <CommissionsPage />, title: 'Комиссия'},

    //Отчеты
    {path: 'reports', element: <ReportsPage />, title: 'Отчеты'},

    //Отделы
    {path: 'departments', element: <DepartmentsPage />, title: 'Отделы'},

    //Сотрудники
    {path: 'employees', element: <EmployeesPage />, title: 'Сотрудники'},

    //Права доступа
    {path: 'rules', element: <RulesPage />, title: 'Права доступа'},

    //Журнал действий
    {path: 'journal', element: <JournalPage />, title: 'Журнал действий'},

    //Настройки
    {path: 'settings', element: <SettingsPage />, title: 'Настройки'},
    {path: 'settings/purchases', element: <SettingsPurchasesPage />, title: 'Настройки / Закупки'},
    {path: 'settings/orders', element: <SettingsOrdersPage />, title: 'Настройки / Приказы'},
    {path: 'settings/complaints', element: <SettingsComplaintsPage />, title: 'Настройки / Жалобы'},
    {path: 'settings/employees', element: <SettingsEmployeesPage />, title: 'Настройки / Сотрудники'},
    {path: 'settings/smtp', element: <SettingsSmtpPage />, title: 'Настройки / Почта (SMTP)'},

    //Мой профиль
    {path: 'profile', element: <ProfilePage />, title: 'Мой профиль'},
    {path: 'profile/interface', element: <ProfileInterfacePage />, title: 'Мой профиль / Настройки интерфейса'},
    {path: 'profile/personal', element: <ProfilePersonalPage />, title: 'Мой профиль / Личные данные'},
    {path: 'profile/password', element: <ProfilePasswordPage />, title: 'Мой профиль / Смена пароля'},

    //Доступ запрещен
    {path: 'accessdenied', element: <AccessDeniedPage />, title: 'Доступ запрещен'},

    //Страница не найдена
    {path: '*', element: <NotFoundPage />, title: 'Страница не найдена'},

]

export const publicRoutes = [

    //Авторизация
    {path: 'login', element: <LoginPage />, title: 'Авторизация'},

    //Выход
    {path: 'logout', element: <LogoutPage />, title: 'Выход'},

]