import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import OrdersService from "../../../services/OrdersServise";
import {getPageCount} from "../../../utils/Pages";
import Preloader from "../../Interface/Preloader";
import {inputChange2d} from "../../../utils/InputChange2d";
import OrdersList from "./OrdersList";
import Pagination from "../../Interface/Pagination";
import OrdersDelForm from "./OrdersDelForm";
import OrdersEditForm from "./OrdersEditForm";
import {dragContentHorizontal} from "../../../utils/DragContentHorizontal";
import SettingsService from "../../../services/SettingsService";
import FormSelect from "../../Interface/FormSelect";
import EmployeesService from "../../../services/EmployeesService";


const OrdersContent = () => {

    //Дополнительные списки
    const [orderTypeList, setOrderTypeList] = useState([]);
    const [torgList, setTorgList] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const [usersList, setUsersList] = useState([]);


    //Список записей
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(localStorage.getItem('config__records_count_orders'));

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    const [modalEdit, setModalEdit] = useState(false);
    const [modalDel, setModalDel] = useState(false);

    const [filters, setFilters] = useState({
        id: {v:'', t:'='},
        purchase__reg_num: {v:'', t:'%'},
        purchase__zak_name: {v:'', t:'%'},
        purchase__method: {v:'null', t:'='},
        purchase__predmet: {v:'', t:'%'},
        purchase__nmck: {v:'', t:'%'},
        user_id: {v:'null', t:'='},
        torg_id: {v:'null', t:'='},
        type: {v:'null', t:'='},
        num: {v:'', t:'%'},
        reg_date: {v:'', t:'%'},
    });

    //Получение доп списков
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            //
            const response1 = await SettingsService.getSettingsHelper('orders_type,orders_torg,purchases_method');
            setOrderTypeList(JSON.parse(response1.data.data.orders_type));
            setTorgList(JSON.parse(response1.data.data.orders_torg));
            setMethodList(JSON.parse(response1.data.data.purchases_method));

            //
            const response2 = await EmployeesService.getAllHelper();
            setUsersList(response2.data.data.items);
        }
    );


    useEffect(() => {
        fetchAdds();
    }, []);


    //
    const references = {
        orderTypeList: orderTypeList,
        torgList: torgList,
        methodList: methodList,
        usersList: usersList,
    };

    //Получение основного списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await OrdersService.getAll(page, limit, 'id', 'desc', filters);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );


    //
    useEffect(() => {
        setPage(1);
    }, [
        filters,
        filters
    ]);


    //
    useEffect(() => {
        fetchItems(page, limit);
    }, [
        page,
        limit,
        updTrigger,
        filters
    ]);


    //
    const changePage = (page) => {
        setPage(page)
    }


    //Редактирование записи
    const editItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalEdit(true);
    }


    //Удаление записи
    const delItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDel(true);
    }


    //Передача функций
    const func = {
        edit: editItemInit,
        del: delItemInit
    }


    //
    if(isAddsLoading)
    {
        return (
            <Preloader />
        );
    }


    //
    return (
        <div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <div className="data_table_div data_table_div_with_filtrs" onMouseDown={e => dragContentHorizontal(e)}>
                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                    <tr>
                        <td>
                            ID
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="id"
                                        value={filters.id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Вид приказа
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="type"
                                        options={orderTypeList}
                                        defaultValue="Выберите значение"
                                        value={filters.type.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="0"
                                        nameKey="1"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Номер приказа
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="num"
                                        value={filters.num.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Дата приказа
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="date"
                                        name="reg_date"
                                        value={filters.reg_date.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Регистрационный номер закупки
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="purchase__reg_num"
                                        value={filters.purchase__reg_num.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Заказчик
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="purchase__zak_name"
                                        value={filters.purchase__zak_name.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Предмет
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="purchase__predmet"
                                        value={filters.purchase__predmet.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Способ закупки
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="purchase__method"
                                        options={methodList}
                                        defaultValue="Выберите значение"
                                        value={filters.purchase__method.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="0"
                                        nameKey="1"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            НМЦК
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="purchase__nmck"
                                        value={filters.purchase__nmck.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Площадка
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="torg_id"
                                        options={torgList}
                                        defaultValue="Выберите значение"
                                        value={filters.torg_id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="0"
                                        nameKey="1"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Кто сформировал приказ
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="user_id"
                                        options={usersList}
                                        defaultValue="Выберите значение"
                                        value={filters.user_id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="id"
                                        nameKey="fio"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>Действия</td>
                    </tr>
                    </thead>
                    <OrdersList
                        items={items}
                        isLoading={isItemsLoading}
                        func={func}
                        references={references}
                    />
                </table>
            </div>

            <Pagination
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />

            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }

            <OrdersDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

            <OrdersEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
                tekPurchaseId={0}
            />

        </div>
    );
};

export default OrdersContent;