import React from 'react';
import TabMenu from "../../components/TabMenu/TabMenu";
import {profileMenu} from "../../menu/ProfileMenu";
import ProfileInterfaceForm from "../../components/Pages/Profile/ProfileInterfaceForm";

const ProfileInterfacePage = () => {
    return (
        <div>
            <div id="page_content_title">Мой профиль</div>

            <TabMenu menu={profileMenu}/>

            <ProfileInterfaceForm />

        </div>
    );
};

export default ProfileInterfacePage;