import React from 'react';
import ReportsContent from "../../components/Pages/Reports/ReportsContent";

const ReportsPage = () => {
    return (
        <div>
            <div id="page_content_title">Отчеты</div>

            <ReportsContent />

        </div>
    );
};

export default ReportsPage;