import React, {useContext, useEffect, useState} from 'react';
import {mainMenu} from "../../../../menu/MainMenu";
import JournalsService from "../../../../services/JournalsService";
import {useFetching} from "../../../../hooks/useFetching";
import {AuthContext} from "../../../../context/AuthContext";
import ActionsWidgetList from "./ActionsWidgetList";

const ActionsWidgetContent = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);

    //Список записей
    const [items, setItems] = useState([])

    //Список разделов
    const sectionsList = mainMenu.filter(
        (menu) => menu.path !== ''
    );
    sectionsList.push({
        path: 'profile',
        title: 'Мой профиль'
    });

    //Список действий
    const actionsList = [
        {value: 'view', name: 'Просмотр'},
        {value: 'add', name: 'Добавление'},
        {value: 'edit', name: 'Редактирование'},
        {value: 'del', name: 'Удаление'},
    ];

    //Фильтры
    const filters = {
        user_id: {v:AppAuthStore.user.id, t:'='},
    };

    //Получение списка действий
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await JournalsService.getAllHelper(1, 10, 'id', 'desc', filters);
            setItems(response.data.data.items);
        }
    );

    //
    useEffect(() => {
        fetchItems();
    }, []);

    //Передача списков
    const references = {
        sectionsList: sectionsList,
        actionsList: actionsList,
    };

    //Вывод
    return (
        <div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <ActionsWidgetList
                items={items}
                isLoading={isItemsLoading}
                references={references}
            />

        </div>
    );
};

export default ActionsWidgetContent;