import React, {useEffect, useState} from 'react';
import {inputChange} from "../../../utils/InputChange";
import SettingsService from "../../../services/SettingsService";
import {useFetching} from "../../../hooks/useFetching";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {errors422} from "../../../utils/Errors422";
import MultiField from "../../Interface/MultiField";
import {RuleEdit} from "../../Interface/Rule/RuleAction";
import {scrollToTop} from "../../../utils/ScrollToTop";

const SettingsComplaintsForm = () => {

    //Получение настроек
    const [settings, setSettings] = useState({
        complaints_review_type: '',
        complaints_category: '',
        complaints_region: '',
        complaints_actions: '',
        complaints_evaluation: '',
        complaints_result: '',
        complaints_prescription: '',
    });

    const [fetchSettings, isLoading, error] = useFetching(
        async () => {
            const response = await SettingsService.getSettings('complaints_review_type,complaints_category,complaints_region,complaints_actions,complaints_evaluation,complaints_result,complaints_prescription');
            setSettings(response.data.data);
        }
    );

    useEffect(() => {
        fetchSettings();
    }, []);


    //Сохранение настроек
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {

        //
        scrollToTop();

        //
        let errArr = [];

        //
        if(errArr.length === 0) {

            //
            try {
                const response = await SettingsService.setSettings(settings);

                if(response.data.status === 'ok')
                {
                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);
                }
                else
                {
                    errArr.push('Ошибка сохранения настроек.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения настроек.');
            }

        }

        //
        setErrors(errArr);

    }

    //
    return (
        <div>

            <FormSuccess ok={success} text="Настройки успешно сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td colSpan="2"><b>Справочники</b></td>
                    </tr>

                    <tr>
                        <td>
                            Тип рассмотрения
                        </td>
                        <td>
                            <MultiField
                                name="complaints_review_type"
                                value={settings.complaints_review_type}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Категория закупки
                        </td>
                        <td>
                            <MultiField
                                name="complaints_category"
                                value={settings.complaints_category}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            Регион Жалобщика
                        </td>
                        <td>
                            <MultiField
                                name="complaints_region"
                                value={settings.complaints_region}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Обжалуемые действия
                        </td>
                        <td>
                            <MultiField
                                name="complaints_actions"
                                value={settings.complaints_actions}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Оценка участия заказчика<br />в рассмотрении жалобы
                        </td>
                        <td>
                            <MultiField
                                name="complaints_evaluation"
                                value={settings.complaints_evaluation}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>


                    <tr>
                        <td>
                            Результат рассмотрения
                        </td>
                        <td>
                            <MultiField
                                name="complaints_result"
                                value={settings.complaints_result}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Предписание УФАС
                        </td>
                        <td>
                            <MultiField
                                name="complaints_prescription"
                                value={settings.complaints_prescription}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <RuleEdit>
                                <input
                                    type="button"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={() => handleSubmit() }
                                    className="rule_edit"
                                />
                            </RuleEdit>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </form>

        </div>
    );
};

export default SettingsComplaintsForm;