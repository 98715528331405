import React from 'react';
import {RuleDel, RuleEdit, RuleView} from "../../Interface/Rule/RuleAction";
import {searchInArray} from "../../../utils/SearchInArray";
import {dateFormat} from "../../../utils/DateFormat";

const OrdersItem = ({item, func, references}) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{searchInArray(references.orderTypeList, item.type_id, 0, 1)}</td>
            <td>{item.num}</td>
            <td>{dateFormat(item.reg_date, 'd.m.Y')}</td>
            <td>{item.purchase.reg_num}</td>
            <td>{item.purchase.zak_name}</td>
            <td>{item.purchase.predmet}</td>
            <td>{searchInArray(references.methodList, item.purchase.method, 0, 1)}</td>
            <td>{item.purchase.nmck}</td>
            <td>{searchInArray(references.torgList, item.torg_id, 0, 1)}</td>
            <td>{searchInArray(references.usersList, item.user_id, 'id', 'fio')}</td>
            <td>
                <div className="action_link_icon_list">

                    <RuleView>
                        <a href="#"
                           className="action_link_icon action_link_icon_view rule_edit"
                           title="Скачать"
                           download={true}
                        >&#xf1c2;</a>
                    </RuleView>

                    <RuleEdit>
                        <a href="#"
                           className="action_link_icon action_link_icon_edit rule_edit"
                           title="Редактировать"
                           onClick={ e => func.edit(e, item.id) }
                        >&#xf044;</a>
                    </RuleEdit>

                    <RuleDel>
                        <a href="#"
                           className="action_link_icon action_link_icon_delete rule_del"
                           title="Удалить"
                           onClick={ e => func.del(e, item.id) }
                        >&#xf00d;</a>
                    </RuleDel>

                </div>
            </td>
        </tr>
    );
};

export default OrdersItem;