import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import CommissionsService from "../../../services/CommissionsServise";
import {getPageCount} from "../../../utils/Pages";
import Preloader from "../../Interface/Preloader";
import {RuleAdd} from "../../Interface/Rule/RuleAction";
import {inputChange2d} from "../../../utils/InputChange2d";
import CommissionsList from "./CommissionsList";
import Pagination from "../../Interface/Pagination";
import CommissionsDelForm from "./CommissionsDelForm";
import CommissionsEditForm from "./CommissionsEditForm";
import {dragContentHorizontal} from "../../../utils/DragContentHorizontal";
import FormSelect from "../../Interface/FormSelect";
import EmployeesService from "../../../services/EmployeesService";


const CommissionsContent = () => {

    //Дополнительные списки
    const [employeesList, setEmployeesList] = useState([]);


    //Список записей
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(localStorage.getItem('config__records_count_commissions'));

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    const [modalEdit, setModalEdit] = useState(false);
    const [modalDel, setModalDel] = useState(false);

    const [filters, setFilters] = useState({
        id: {v:'', t:'='},
        num: {v:'', t:'%'},
        chairman: {v:'', t:'%'},
        secretary: {v:'', t:'%'},
        member1: {v:'', t:'%'},
        member2: {v:'', t:'%'},
        actual_status: {v:'null', t:'='},
        actual_date: {v:'', t:'%'},
    });


    //Получение доп. списков
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            const response1 = await EmployeesService.getAllHelper();
            setEmployeesList(response1.data.data.items);
        }
    );

    useEffect(() => {
        fetchAdds();
    }, []);


    //Статусы актуальности
    const actualsList = [
        {value: '0', name: 'нет'},
        {value: '1', name: 'да'},
    ];


    //Передача доп. списков
    const references = {
        actualsList: actualsList,
        employeesList: employeesList
    }


    //Получение основного списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await CommissionsService.getAll(page, limit, 'id', 'asc', filters);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );


    //
    useEffect(() => {
        setPage(1);
    }, [
        filters,
        filters
    ]);


    //
    useEffect(() => {
        fetchItems(page, limit);
    }, [
        page,
        limit,
        updTrigger,
        filters
    ]);


    //
    const changePage = (page) => {
        setPage(page)
    }


    //Добавление записи
    const addItemInit = (e) => {
        e.preventDefault();
        setTekItemId(0);
        setModalEdit(true);
    }


    //Редактирование записи
    const editItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalEdit(true);
    }


    //Удаление записи
    const delItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDel(true);
    }


    //Передача функций
    const func = {
        edit: editItemInit,
        del: delItemInit
    }


    //
    if(isAddsLoading)
    {
        return (
            <Preloader />
        );
    }


    //
    return (
        <div>

            <div className="btn_right_top_panel">

                <RuleAdd>
                    <a href="#"
                       className="btn btn_with_icon btn_modal rule_add"
                       onClick={ e => addItemInit(e) }
                    >
                        <span>&#xf067;</span> Добавить закупку
                    </a>
                </RuleAdd>

            </div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <div className="data_table_div data_table_div_with_filtrs" onMouseDown={e => dragContentHorizontal(e)}>
                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                    <tr>
                        <td>
                            ID
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="id"
                                        value={filters.id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Номер состава
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="num"
                                        value={filters.num.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            ФИО председателя комиссии
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="chairman"
                                        value={filters.chairman.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            ФИО секретаря комиссии
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="secretary"
                                        value={filters.secretary.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            ФИО члена комиссии №1
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="member1"
                                        value={filters.member1.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            ФИО члена комиссии №2
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="member2"
                                        value={filters.member2.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Актуальный состав
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="actual_status"
                                        options={actualsList}
                                        defaultValue="Выберите значение"
                                        value={filters.actual_status.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Актуален с даты
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="date"
                                        name="actual_date"
                                        value={filters.actual_date.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>Действия</td>
                    </tr>
                    </thead>
                    <CommissionsList
                        items={items}
                        isLoading={isItemsLoading}
                        func={func}
                        references={references}
                    />
                </table>
            </div>

            <Pagination
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />

            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }

            <CommissionsDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

            <CommissionsEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
            />

        </div>
    );
};

export default CommissionsContent;