import React from 'react';
import Logo from '../assets/img/logo.png';
import MainMenu from "../components/LeftPanel/MainMenu";
import DateTimeInformer from "../components/LeftPanel/DateTimeInformer";
import UserInformer from "../components/LeftPanel/UserInformer";
import UserMenu from "../components/LeftPanel/UserMenu";

const LeftPanel = () => {
    return (
        <div id="page_left">

            <div id="site_logo">
                <img src={Logo} alt=""/>
            </div>

            <div id="site_name">
                Комитет Ставропольского края<br />
                по государственным закупкам
            </div>

            <div id="site_desc">
                Документооборот по закупкам
            </div>

            <DateTimeInformer />

            <UserInformer />

            <MainMenu />

            <UserMenu />

        </div>
    );
};

export default LeftPanel;