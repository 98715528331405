import React from 'react';
import ReactMoment from 'react-moment';
import 'moment-timezone';

const DateTimeInformer = () => {
    return (
        <div id="site_time">
            <span>
                <ReactMoment interval={3000} format="DD.MM.YYYY / HH:mm" />
            </span>
        </div>
    );
};

export default DateTimeInformer;