import React, {useContext}  from 'react';

import Logo from '../../assets/img/logo.png';
import LoginForm from "../../components/Pages/Login/LoginForm";

import {observer} from "mobx-react-lite"
import {AuthContext} from "../../context/AuthContext";
import {Navigate} from "react-router-dom";

const LoginPage = () => {

    document.title = 'Авторизация - Документооборот по закупкам';

    const {AppAuthStore} = useContext(AuthContext);

    //
    if(!AppAuthStore.isCheckAuth)
    {
        return '';
    }


    else
    {
        console.log();
    }


    //
    return (

        AppAuthStore.isAuth
            ?
                <Navigate to={AppAuthStore.jsonSettings['home_page']} replace />
            :
                <div id="wrap_page_auth">

                    <div id="page_auth">

                        <div id="page_auth_head">
                            <div id="page_auth_head_logo">
                                <img src={Logo}/>
                            </div>
                            <div id="page_auth_head_text">
                                <div id="page_auth_head_name">
                                    Комитет Ставропольского края<br />
                                    по государственным закупкам
                                </div>
                                <div id="page_auth_head_desc">
                                    Документооборот по закупкам
                                </div>
                            </div>
                        </div>

                        <div id="page_auth_title">Пожалуйста, авторизуйтесь</div>

                        <LoginForm />

                    </div>

                </div>

    );
};

export default observer(LoginPage);