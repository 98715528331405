import React from 'react';
import JournalContent from "../../components/Pages/Journal/JournalContent";

const JournalPage = () => {
    return (
        <div>
            <div id="page_content_title">Журнал действий</div>

            <JournalContent />

        </div>
    );
};

export default JournalPage;