import axios from "axios";


export const API_URL = process.env.REACT_APP_BACKEND_HOST;

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
});

$api.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
        return config
    }
);

$api.interceptors.response.use(
    (config) => {
            return config;
        },
    async (error) => {

        if(error.response.status === 403)
        {
            window.location.href = '/accessdenied';
        }
        else
        if(error.response.status === 401 && error.config && localStorage.getItem('token') && !error.config._isRetry && error.config.url !== '/auth/logout' && error.config.url !== '/auth/login') {
            const originalRequest = error.config;
            originalRequest._isRetry = true;

            try {
                const response = await $api.post('/auth/refresh');
                localStorage.setItem('token', response.data.access_token);
                return $api.request(originalRequest);
            }
            catch (e) {
                //console.log(e);
            }

        }
        throw error;
    }
);


export default $api;