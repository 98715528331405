import React from 'react';
import ActionsWidgetItem from "./ActionsWidgetItem";
import {dragContentHorizontal} from "../../../../utils/DragContentHorizontal";

const ActionsWidgetList = ({items, isLoading, references}) => {

    //Нет данных
    if (!items.length && ! isLoading) {
        return (
            <div>Записи не найдены!</div>
        )
    }

    //Вывод
    return (
        <div className="data_table_div data_table_div_in_widget" onMouseDown={e => dragContentHorizontal(e)}>
            <table cellPadding="0" cellSpacing="0" border="0">
                <thead>
                <tr>
                    <td>ID</td>
                    <td>Раздел</td>
                    <td>Действие</td>
                    <td>Время</td>
                </tr>
                </thead>
                <tbody>
                {
                    items.map(
                        (item, index) =>
                            <ActionsWidgetItem
                                key={item.id}
                                item={item}
                                references={references}
                            />
                    )
                }
                </tbody>
            </table>
        </div>
    );

};

export default ActionsWidgetList;