import React, {useContext, useState, useEffect} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import FormSelect from "../../Interface/FormSelect";
import {inputChange} from "../../../utils/InputChange";
import {useFetching} from "../../../hooks/useFetching";
import ProfileService from "../../../services/ProfileService";
import {errors422} from "../../../utils/Errors422";


const ProfileInterfaceForm = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);

    //Получение настроек
    const [userInterface, setUserInterface] = useState({
        home_page: '',
        records_count_purchases: '',
        records_count_orders: '',
        records_count_complaints: '',
        records_count_commissions: '',
        records_count_departments: '',
        records_count_employees: '',
        records_count_rules: '',
        records_count_journal: '',
    });

    const homePageArr = [
        {value: '/', name: 'Главная'},
        {value: '/purchases', name: 'Закупки'},
        {value: '/orders', name: 'Приказы'},
        {value: '/complaints', name: 'Жалобы'},
        {value: '/commissions', name: 'Комиссия'},
        {value: '/reports', name: 'Отчеты'},
    ]

    const recordsCountArr = [
        {value: 5, name: 5},
        {value: 10, name: 10},
        {value: 15, name: 15},
        {value: 20, name: 20},
        {value: 25, name: 25},
        {value: 30, name: 30},
    ]

    const [fetchInterfaceById, isLoading, error] = useFetching(
        async (id) => {
            const response = await ProfileService.getInterfaceById(id);
            setUserInterface(response.data.data);
        }
    );

    useEffect(() => {
        fetchInterfaceById(AppAuthStore.user.id);
    }, []);


    //Сохранение настроек
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {

        //
        let errArr = [];

        //
        if(errArr.length === 0) {

            //
            try {
                const response = await ProfileService.updateInterfaceById(AppAuthStore.user.id, userInterface);

                if(response.data.status === 'ok')
                {
                    AppAuthStore.getUserSettings(userInterface);

                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);
                }
                else
                {
                    errArr.push('Ошибка сохранения настроек.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения настроек.');
            }

        }

        //
        setErrors(errArr);
    }


    //
    return (
        <div>

            <FormSuccess ok={success} text="Настройки успешно сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td>Первая страница</td>
                        <td>
                            <FormSelect
                                name="home_page"
                                options={homePageArr}
                                defaultValue="Выберите значение"
                                value={userInterface.home_page}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2"><b>Количество записей в таблицах</b></td>
                    </tr>

                    <tr>
                        <td>Закупки</td>
                        <td>
                            <FormSelect
                                name="records_count_purchases"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_purchases}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Приказы</td>
                        <td>
                            <FormSelect
                                name="records_count_orders"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_orders}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Жалобы</td>
                        <td>
                            <FormSelect
                                name="records_count_complaints"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_complaints}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Комиссия</td>
                        <td>
                            <FormSelect
                                name="records_count_commissions"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_commissions}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Отделы</td>
                        <td>
                            <FormSelect
                                name="records_count_departments"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_departments}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Сотрудники</td>
                        <td>
                            <FormSelect
                                name="records_count_employees"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_employees}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Права доступа</td>
                        <td>
                            <FormSelect
                                name="records_count_rules"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_rules}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Журнал действий</td>
                        <td>
                            <FormSelect
                                name="records_count_journal"
                                options={recordsCountArr}
                                defaultValue="Выберите значение"
                                value={userInterface.records_count_journal}
                                onChange={ e => setUserInterface(inputChange(e, userInterface)) }
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="button"
                                name="submit"
                                value="Сохранить"
                                onClick={() => handleSubmit() }
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>
            </form>

        </div>
    );
};

export default ProfileInterfaceForm;