import React, {useContext, useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import PurchasesServise from "../../../services/PurchasesServise";
import {errors422} from "../../../utils/Errors422";
import {checkField} from "../../../utils/CheckField";
import {AuthContext} from "../../../context/AuthContext";
import {purchasesFields} from "../../../configs/PurchasesFields";
import PurchasesEditTr from "./PurchasesEditTr";
import {checkFieldEtap} from "../../../utils/СheckFieldEtap";


const PurchasesEditForm = ({modal, setModal, tekItemId, setTekItemId, setUpdTrigger, references}) => {

    //
    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userPurchasesFields = AppAuthStore.purchasesFields;

    //Поля пользователя
    let userFields = purchasesFields.filter(
        (field) => checkField(userPurchasesFields, field.name, 'edit')
    );

    //
    let FormTitle = '';
    let FieldEtap = '';

    //
    const nullItem = {

    };

    if(tekItemId === 0)
    {
        //
        FormTitle = 'Добавление закупки';

        //
        FieldEtap = 'add';
    }
    else
    {
        //
        FormTitle = 'Редактирование закупки';

        //
        FieldEtap = 'edit';
    }

    //
    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const [addFromRisNum, setAddFromRisNum] = useState(false);
    const [donorItem, setDonorItem] = useState(nullItem);

    //
    useEffect(() => {

        if(modal)
        {
            //Получение данных
            if (tekItemId === 0)
            {
                const getLastRegNum = async (id) => {
                    const response = await PurchasesServise.getLast();
                    const lastRegNum = response.data.data.reg_num !== undefined ? response.data.data.reg_num : 0;
                    nullItem.reg_num = lastRegNum + 1;
                    setItem(nullItem);
                }
                getLastRegNum();
            }
            else
            {
                const getItem = async (id) => {
                    const response = await PurchasesServise.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }

            //Проверка наличия закупки по номеру РИС
            document.addEventListener('keyup', function(e){
                const target = e.target.closest('input[name="ris_num"]');
                if(target)
                {
                    let tekRisNum = e.target.value;

                    const getItemRisNum = async (num) => {
                        const response = await PurchasesServise.getSearch('ris_num', '=', num);

                        if(response.data.data.reg_num !== undefined)
                        {
                            setAddFromRisNum(true);
                            setDonorItem(response.data.data);
                        }
                        else
                        {
                            setAddFromRisNum(false);
                        }
                    }

                    getItemRisNum(tekRisNum);
                }
            });

        }

    }, [modal, modal]);


    //Заполнить форму по номеру Рис
    const fillFromRisNumSubmit = async (e) => {

        //
        e.preventDefault();

        //
        setAddFromRisNum(false);

        //
        setTekItemId(donorItem.id);
        donorItem.status = 2;
        setItem(donorItem);
    }


    //Сохранение данных
    const handleSubmit = async (e) => {

        //
        e.preventDefault();

        //
        let errArr = [];


        //
        if(item.receipt_date === '')
        {
            errArr.push('Поле «Дата поступления» не заполнено');
        }

        //
        if(item.reg_num === '')
        {
            errArr.push('Поле «Регистрационный номер» не заполнено');
        }

        //
        if(errArr.length === 0)
        {
            try {
                //
                let response;

                if(tekItemId === 0)
                {
                    response = await PurchasesServise.create(item);
                }
                else
                {
                    response = await PurchasesServise.updateById(tekItemId, item);
                }

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения закупки.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения закупки.');
            }

        }

        //
        setErrors(errArr);
    }


    //
    return (
        <ModalWindow title={FormTitle} active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            {
                addFromRisNum
                    ?
                    <div className="form_message">
                        <div className="form_message_title">Закупка после доработки</div>
                        <div className="form_message_text">

                            <p>По указанному значеию поля «Номер закупки в РИС» найдена<br />
                                закупка. Заполнить поля информацией из этой закупки?</p>

                            <div align="center">
                                <input
                                    type="submit"
                                    name="submit_fill"
                                    value="Заполнить"
                                    onClick={ e => fillFromRisNumSubmit(e) }
                                />
                            </div>

                        </div>
                    </div>
                    :
                    <></>
            }

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                        {
                            userFields.filter(
                                (field) => checkFieldEtap(field.etap, FieldEtap)
                            ).map(
                                (tr, index) =>
                                    <PurchasesEditTr
                                        key={tr.name}
                                        field={tr}
                                        value={item[tr.name]}
                                        references={references}
                                        item={item}
                                        setItem={setItem}
                                    />
                            )
                        }

                        <tr>
                            <td></td>
                            <td>
                                <input
                                    type="submit"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={ e => handleSubmit(e) }
                                />
                            </td>
                        </tr>

                    </tbody>
                </table>

            </form>

        </ModalWindow>
    );
};

export default PurchasesEditForm;