import $api from "../http";

export default class UploadService {

    static async uploadFile(data) {
        return await $api.post('/files/upload', data);
    }

    static async deleteFile(id) {
        return await $api.delete('/files/' + id + '/delete');
    }

    static async updateFiles(files, status) {
        return await $api.post('/files/update',  { files: files, status: status });
    }

}