import React from 'react';

const AccessDeniedPage = () => {
    return (
        <div>
            <div id="page_content_title">Доступ запрещен</div>

            <p>
                Запрошенная страница Вам не доступна. <br />
                Выберите необходимый раздел в левом меню.
            </p>

        </div>
    );
};

export default AccessDeniedPage;