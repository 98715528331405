import React, {useContext, useState} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import {inputChange} from "../../../utils/InputChange";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import ProfileService from "../../../services/ProfileService";
import {errors422} from "../../../utils/Errors422";

const ProfilePasswordForm = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //Получение данных
    const [pass, setPass] = useState({password: '', password_confirmation: ''});


    //Смена пароля
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {

        //
        let errArr = [];

        //
        if(pass.password === '')
        {
            errArr.push('Поле «Пароль» не заполнено');
        }

        //
        if(pass.password_confirmation === '')
        {
            errArr.push('Поле «Подтверждение пароля» не заполнено');
        }

        //
        if( (pass.password !== '') && (pass.password_confirmation !== '') && (pass.password !== pass.password_confirmation) )
        {
            errArr.push('Поля «Пароль» и «Подтверждение пароля» не совпадают');
        }


        //
        if(errArr.length === 0) {

            //
            try {
                const response = await ProfileService.passwordChangeById(AppAuthStore.user.id, pass);

                if(response.data.status === 'ok')
                {
                    setPass({password: '', password_confirmation: ''});

                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);
                }
                else
                {
                    errArr.push('Ошибка смены пароля.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка смены пароля.');
            }

        }

        //
        setErrors(errArr);
    }


    //
    return (
        <div>

            <FormSuccess ok={success} text="Пароль успешно изменён." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td>Пароль <span className="zv">*</span></td>
                        <td>
                            <input
                                type="password"
                                name="password"
                                value={pass.password}
                                onChange={e => setPass(inputChange(e, pass))}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Подтверждение пароля <span className="zv">*</span></td>
                        <td>
                            <input
                                type="password"
                                name="password_confirmation"
                                value={pass.password_confirmation}
                                onChange={e => setPass(inputChange(e, pass))}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="button"
                                name="submit"
                                value="Сменить пароль"
                                onClick={() => handleSubmit() }
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>
            </form>

        </div>
    );
};

export default ProfilePasswordForm;