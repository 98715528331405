import React from 'react';
import {RuleDel, RuleEdit} from "../../Interface/Rule/RuleAction";
import {searchInArray, searchInArrayJson} from "../../../utils/SearchInArray";
import {dateFormat} from "../../../utils/DateFormat";

const ComplaintsItem = ({item, func, references}) => {

    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.purchase.reg_num}</td>
            <td>{searchInArray(references.reviewTypeList, item.review_type, 0, 1)}</td>
            <td>{searchInArray(references.NoYesList, item.gku_doks, 0, 1)}</td>
            <td>{searchInArray(references.categoryList, item.category, 0, 1)}</td>
            <td>{item.complainer_name}</td>
            <td>{searchInArray(references.regionList, item.complainer_region, 0, 1)}</td>
            <td>{searchInArrayJson(references.actionsList, item.actions, 0, 1)}</td>
            <td>{dateFormat(item.review_date, 'd.m.Y')}</td>
            <td>{searchInArray(references.evaluationList, item.evaluation, 0, 1)}</td>
            <td>{searchInArray(references.resultList, item.result, 0, 1)}</td>
            <td>{searchInArrayJson(references.prescriptionList, item.prescription, 0, 1)}</td>
            <td>{item.additional}</td>
            <td>
                <div className="action_link_icon_list">

                    <RuleEdit>
                        <a href="#"
                           className="action_link_icon action_link_icon_edit rule_edit"
                           title="Редактировать"
                           onClick={ e => func.edit(e, item.id) }
                        >&#xf044;</a>
                    </RuleEdit>

                    <RuleDel>
                        <a href="#"
                           className="action_link_icon action_link_icon_delete rule_del"
                           title="Удалить"
                           onClick={ e => func.del(e, item.id) }
                        >&#xf00d;</a>
                    </RuleDel>

                </div>
            </td>
        </tr>
    );
};

export default ComplaintsItem;