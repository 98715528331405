import React, {useContext, useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {inputChange} from "../../../utils/InputChange";
import OrdersServise from "../../../services/OrdersServise";
import {errors422} from "../../../utils/Errors422";
import PurchasesServise from "../../../services/PurchasesServise";
import FormSelect from "../../Interface/FormSelect";
import {AuthContext} from "../../../context/AuthContext";
import SettingsService from "../../../services/SettingsService";
import {getNextElem} from "../../../utils/GetNextElem";
import {dateFormat} from "../../../utils/DateFormat";

const inputChangeType = (e, item, torgId, setTplList, tplListArr) => {

    //
    item = inputChange(e, item);

    //
    if(item.type_id === '1')
    {
        item.torg_id = torgId;
    }
    else
    {
        item.torg_id = 0;
    }

    //
    item.tpl_id = 0;

    //
    setTplList(tplListArr[item.type_id]);

    //
    return item;
}

const OrdersEditForm = ({modal, setModal, tekItemId, setUpdTrigger, references, tekPurchaseId}) => {

    //
    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //
    let FormTitle = '';
    let nextOrderTorgId = 0;
    let tplListArrNull = {};
    let references_mod;

    //
    let nullItem = {
        purchase:{
            reg_num:''
        }
    };

    if(tekItemId === 0)
    {
        FormTitle = 'Формирование приказа';

        //
        nullItem['purchase_id'] = tekPurchaseId;
    }
    else
    {
        FormTitle = 'Редактирование приказа';
    }


    //
    const [item, setItem] = useState(nullItem);

    const [tplList, setTplList] = useState([]);
    const [tplListArr, setTplListArr] = useState([]);
    const [nextOrder, setNextOrder] = useState(nextOrderTorgId);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {

            //Список шаблонов
            const getTplList = async () => {
                const response0 = await SettingsService.getSettings('orders_tpl_publication1_title,orders_tpl_publication2_title,orders_tpl_cancel1_title,orders_tpl_cancel2_title,orders_tpl_changes1_title,orders_tpl_changes2_title');

                tplListArrNull = {
                    0:[
                    ],
                    1:[
                        ['1', response0.data.data.orders_tpl_publication1_title],
                        ['2', response0.data.data.orders_tpl_publication2_title],
                    ],
                    2:[
                        ['1', response0.data.data.orders_tpl_cancel1_title],
                        ['2', response0.data.data.orders_tpl_cancel2_title],
                    ],
                    3:[
                        ['1', response0.data.data.orders_tpl_changes1_title],
                        ['2', response0.data.data.orders_tpl_changes2_title],
                    ],
                };

                setTplListArr(tplListArrNull);

                return tplListArrNull;
            }

            getTplList();

            //Сохранение
            if (tekItemId === 0) {
                const getPurchaseRegNum = async (id) => {
                    //
                    const response1 = await PurchasesServise.getById(id);
                    nullItem.purchase.reg_num = response1.data.data.reg_num !== undefined ? response1.data.data.reg_num : '';


                    //
                    nullItem.user_id = AppAuthStore.user.id;


                    //
                    const response2 = await OrdersServise.getLast();
                    const lastOrderNum = response2.data.data.num !== undefined ? response2.data.data.num : 0;

                    if(lastOrderNum !== 0)
                    {
                        nullItem.num = lastOrderNum + 1;
                    }
                    else
                    {
                        const response21 = await SettingsService.getSettings('orders_num');
                        nullItem.num = response21.data.data.orders_num;
                    }

                    //
                    const response3 = await OrdersServise.getLast();
                    const lastOrderTorgId = response2.data.data.torg_id !== response3 ? response2.data.data.torg_id : 0;
                    nextOrderTorgId = getNextElem(lastOrderTorgId, references.torgList, 0);
                    setNextOrder(nextOrderTorgId);

                    if(nullItem.type_id == 1)
                    {
                        nullItem.torg_id = nextOrderTorgId;
                    }

                    //
                    setItem(nullItem);

                    //Доступные типы
                    references_mod = references;
                    references_mod.orderTypeList = references_mod.orderTypeList.filter(function(type){

                        if(response1.data.data.status === 1)
                        {
                            return type[0] === '1';
                        }
                        else
                        if(response1.data.data.status === 5)
                        {
                            if(response1.data.data.orders_count_2 === 0)
                            {
                                return type[0] === '2' || type[0] === '3';
                            }
                            else
                            {
                                return type[0] === '3';
                            }
                        }

                    });
                }
                getPurchaseRegNum(tekPurchaseId);
            }
            else
            {
                //
                const getItem = async (id) => {
                    //
                    const response = await OrdersServise.getById(id);
                    setItem(response.data.data);

                    //
                    const tplListArr1 = await getTplList();
                    setTplList(tplListArr1[response.data.data.type_id]);
                }

                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async (e) => {

        //
        e.preventDefault();

        //
        let errArr = [];

        //
        if(item.purchase.reg_num === '')
        {
            errArr.push('Поле «Регистрационный номер закупки» не заполнено');
        }

        //
        if(errArr.length === 0)
        {
            try {
                //
                let response;

                if(tekItemId === 0)
                {
                    //Создание приказа
                    response = await OrdersServise.create(item);

                    //Установить статус закупки
                    let purchasesStatus = 0;

                    if(item.type_id === '1')
                    {
                        purchasesStatus = 4;
                    }
                    else
                    if(item.type_id === '2')
                    {
                        purchasesStatus = 6;
                    }

                    if(purchasesStatus !== 0)
                    {
                        const response1 = await PurchasesServise.updateById(tekPurchaseId, {status: purchasesStatus});
                    }
                }
                else
                {
                    //Обновление приказа
                    response = await OrdersServise.updateById(tekItemId, item);
                }

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения закупки.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения закупки.');
            }

        }

        //
        setErrors(errArr);
    }


    //
    return (
        <ModalWindow title={FormTitle} active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td>Регистрационный номер закупки <span className="zv">*</span></td>
                        <td>
                            <input
                                type="text"
                                name="purchase_reg_num"
                                value={item.purchase.reg_num || ''}
                                readOnly={true}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Вид приказа <span className="zv">*</span></td>
                        <td>
                            <FormSelect
                                name="type_id"
                                options={references.orderTypeList}
                                defaultValue="Выберите значение"
                                value={item.type_id}
                                onChange={ e => setItem(inputChangeType(e, item, nextOrder, setTplList, tplListArr)) }
                                valueKey="0"
                                nameKey="1"
                                defValue="0"
                                defName=""
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Номер приказа <span className="zv">*</span></td>
                        <td>
                            <input
                                type="text"
                                name="num"
                                value={item.num || ''}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Дата приказа <span className="zv">*</span></td>
                        <td>
                            <input
                                type="date"
                                name="reg_date"
                                value={item.reg_date ? dateFormat(item.reg_date, 'Y-m-d') : ''}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Шаблон приказа <span className="zv">*</span></td>
                        <td>
                            <FormSelect
                                name="tpl_id"
                                options={tplList}
                                defaultValue="Выберите значение"
                                value={item.tpl_id}
                                onChange={ e => setItem(inputChange(e, item)) }
                                valueKey="0"
                                nameKey="1"
                                defValue="0"
                                defName=""
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Площадка</td>
                        <td>
                            <FormSelect
                                name="torg_id"
                                options={references.torgList}
                                defaultValue="Выберите значение"
                                value={item.torg_id}
                                onChange={ e => setItem(inputChange(e, item)) }
                                valueKey="0"
                                nameKey="1"
                                defValue="0"
                                defName=""
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="submit"
                                name="submit"
                                value="Сохранить"
                                onClick={ e => handleSubmit(e) }
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>

            </form>

        </ModalWindow>
    );
};

export default OrdersEditForm;