import React, {useEffect, useState, useContext} from 'react';
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import ProfileService from "../../../services/ProfileService";
import {useFetching} from "../../../hooks/useFetching";
import {inputChange} from "../../../utils/InputChange";
import {AuthContext} from "../../../context/AuthContext";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {errors422} from "../../../utils/Errors422";

const ProfilePersonalForm = () => {


    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);


    //Получение данных
    const [profile, setProfile] = useState({phone: '', email: ''});

    const [fetchProfileById, isLoading, error] = useFetching(
        async (id) => {
            const response = await ProfileService.getById(id);
            setProfile(response.data.data);
        }
    );

    useEffect(() => {
        fetchProfileById(AppAuthStore.user.id);
    }, []);


    //Сохранение данных
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {
        //
        let errArr = [];

        //
        if(errArr.length === 0)
        {
            //
            try {
                //
                const response = await ProfileService.updateById(AppAuthStore.user.id, profile);

                if(response.data.status === 'ok')
                {
                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);
                }
                else
                {
                    errArr.push('Ошибка сохранения.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения настроек.');
            }

        }

        setErrors(errArr);
    }

    //Вывод формы
    return (
        <div>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td>Номер телефона</td>
                        <td>
                            <input
                                type="text"
                                name="phone"
                                value={profile.phone || ''}
                                onChange={e => setProfile(inputChange(e, profile))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Электронная почта</td>
                        <td>
                            <input
                                type="text"
                                name="email"
                                value={profile.email || ''}
                                onChange={e => setProfile(inputChange(e, profile))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="button"
                                name="submit"
                                value="Сохранить"
                                onClick={() => handleSubmit() }
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>
            </form>

        </div>
    );

};

export default ProfilePersonalForm;