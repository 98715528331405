import React from 'react';
import CommissionsItem from "./CommissionsItem";

const CommissionsList = ({items, isLoading, func, references}) => {

    //
    if (!items.length && ! isLoading) {
        return (
            <tbody>
                <tr>
                    <td colSpan="9">Записи не найдены!</td>
                </tr>
            </tbody>
        )
    }

    //
    return (
        <tbody>
            {
                items.map(
                    (item, index) =>
                        <CommissionsItem
                            key={item.id}
                            item={item}
                            func={func}
                            references={references}
                        />
                )
            }
        </tbody>
    );
};

export default CommissionsList;