import React, {useEffect, useState, useContext} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import PurchasesService from "../../../services/PurchasesServise";
import {getPageCount} from "../../../utils/Pages";
import Preloader from "../../Interface/Preloader";
import {RuleAdd} from "../../Interface/Rule/RuleAction";
import PurchasesList from "./PurchasesList";
import Pagination from "../../Interface/Pagination";
import PurchasesDelForm from "./PurchasesDelForm";
import PurchasesEditForm from "./PurchasesEditForm";
import {dragContentHorizontal} from "../../../utils/DragContentHorizontal";
import SettingsService from "../../../services/SettingsService";
import {purchasesFields} from "../../../configs/PurchasesFields";
import {AuthContext} from "../../../context/AuthContext";
import PurchasesTh from "./PurchasesTh";
import EmployeesService from "../../../services/EmployeesService";
import CommissionsServise from "../../../services/CommissionsServise";
import PurchasesViewForm from "./PurchasesViewForm";
import ComplaintsEditForm from "../Complaints/ComplaintsEditForm";
import {checkField} from "../../../utils/CheckField";
import PurchasesTUserForm from "./PurchasesTUserForm";
import PurchasesCWorkForm from "./PurchasesCWorkForm";
import OrdersEditForm from "../Orders/OrdersEditForm";
import {checkboxChecked2d, isChecked} from "../../../utils/CheckboxFun";


const PurchasesContent = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userPurchasesFields = AppAuthStore.purchasesFields;

    //Дополнительные списки
    const [zakTypeList, setZakTypeList] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const [urgencyList, setUrgencyList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [cancelReasonList, setCancelReasonList] = useState([]);
    const [changesReasonList, setChangesReasonList] = useState([]);
    const [nottakeReasonList, setNottakeReasonList] = useState([]);

    const [contractorList, setContractorList] = useState([]);
    const [commissionsList, setCommissionsList] = useState([]);

    //Для формирования приказов
    const [orderTypeList, setOrderTypeList] = useState([]);
    const [torgList, setTorgList] = useState([]);

    //Для формирования жалоб
    const [reviewTypeList, setReviewTypeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [actionsList, setActionsList] = useState([]);
    const [evaluationList, setEvaluationList] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [prescriptionList, setPrescriptionList] = useState([]);

    //Список записей
    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(localStorage.getItem('config__records_count_purchases'));

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    //Модальные окна
    const [modalView, setModalView] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDel, setModalDel] = useState(false);
    const [modalOrder, setModalOrder] = useState(false);
    const [modalComplaint, setModalComplaint] = useState(false);
    const [modalTUser, setModalTUser] = useState(false);
    const [modalCWork, setModalCWork] = useState(false);

    //Поля пользователя
    let userFields = purchasesFields.filter(
        (field) => checkField(userPurchasesFields, field.name, 'view1')
    );

    //Фильтры
    let filtersNull = {};
    let filtersValue = '';
    let filtersType = '';

    for (var key in userFields) {

        switch (userFields[key].type) {

            case 'date':
                filtersValue = '';
                filtersType = '%';
                break;

            case 'ref':
                filtersValue = 'null';
                filtersType = '=';
                break;

            default:
                filtersValue = '';
                filtersType = '%';
        }

        filtersNull[userFields[key].name] = {
            v:filtersValue,
            t:filtersType
        };
    }

    //Фильтр по исполнителю
    if(AppAuthStore.executor == 1)
    {
        filtersNull['contractor'] = {
            v:AppAuthStore.user.id,
            t:'='
        };
    }


    //Фильтр по статусу завершения
    filtersNull['status-1'] = {
        v:[2, 3],
        t:'!in'
    };


    const [filters, setFilters] = useState(filtersNull);


    //Получение доп списков
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            //
            const response1 = await SettingsService.getSettingsHelper('purchases_zak_type,purchases_method,purchases_urgency,purchases_status,orders_type,orders_torg,complaints_review_type,complaints_category,complaints_region,complaints_actions,complaints_evaluation,complaints_result,complaints_prescription,purchases_cancel_reason,purchases_changes_reason,purchases_nottake_reason');
            setZakTypeList(JSON.parse(response1.data.data.purchases_zak_type));
            setMethodList(JSON.parse(response1.data.data.purchases_method));
            setUrgencyList(JSON.parse(response1.data.data.purchases_urgency));
            setStatusList(JSON.parse(response1.data.data.purchases_status));
            setCancelReasonList(JSON.parse(response1.data.data.purchases_cancel_reason));
            setChangesReasonList(JSON.parse(response1.data.data.purchases_changes_reason));
            setNottakeReasonList(JSON.parse(response1.data.data.purchases_nottake_reason));

            //
            const response2 = await EmployeesService.getAllHelper(1, 100, 'id', 'asc', {executor: {v:'1', t:'='}});
            setContractorList(response2.data.data.items);

            //
            const response3 = await CommissionsServise.getAllHelper();
            setCommissionsList(response3.data.data.items);

            //Для формирования приказов
            setOrderTypeList(JSON.parse(response1.data.data.orders_type));
            setTorgList(JSON.parse(response1.data.data.orders_torg));

            //Для формирования жалоб
            setReviewTypeList(JSON.parse(response1.data.data.complaints_review_type));
            setCategoryList(JSON.parse(response1.data.data.complaints_category));
            setRegionList(JSON.parse(response1.data.data.complaints_region));
            setActionsList(JSON.parse(response1.data.data.complaints_actions));
            setEvaluationList(JSON.parse(response1.data.data.complaints_evaluation));
            setResultList(JSON.parse(response1.data.data.complaints_result));
            setPrescriptionList(JSON.parse(response1.data.data.complaints_prescription));
        }
    );

    useEffect(() => {
        fetchAdds();
    }, []);

    //Нет/Да
    const NoYesList = [
        ['0', 'Нет'],
        ['1', 'Да'],
    ];

    //Передача доп. списков
    const references = {
        NoYesList: NoYesList,
        zakTypeList: zakTypeList,
        methodList: methodList,
        urgencyList: urgencyList,
        statusList: statusList,
        cancelReasonList: cancelReasonList,
        changesReasonList: changesReasonList,
        nottakeReasonList: nottakeReasonList,

        contractorList: contractorList,
        commissionsList: commissionsList,

        orderTypeList: orderTypeList,
        torgList: torgList,

        reviewTypeList: reviewTypeList,
        categoryList: categoryList,
        regionList: regionList,
        actionsList: actionsList,
        evaluationList: evaluationList,
        resultList: resultList,
        prescriptionList: prescriptionList,
    };

    //Получение основного списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await PurchasesService.getAll(page, limit, 'id', 'desc', filters);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );

    //
    useEffect(() => {
        setPage(1);
    }, [
        filters,
        filters
    ]);

    //
    useEffect(() => {
        fetchItems(page, limit);
    }, [
        page,
        limit,
        updTrigger,
        filters
    ]);

    //
    const changePage = (page) => {
        setPage(page)
    }

    //Просмотр записи
    const viewItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalView(true);
    }

    //Добавление записи
    const addItemInit = (e) => {
        e.preventDefault();
        setTekItemId(0);
        setModalEdit(true);
    }

    //Редактирование записи
    const editItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalEdit(true);
    }

    //Удаление записи
    const delItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDel(true);
    }

    //Сформировать приказ
    const orderItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalOrder(true);
    }

    //Сформировать жалобу
    const complaintItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalComplaint(true);
    }

    //Назначение исполнителя
    const tuserItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalTUser(true);
    }

    //Работа исполнителя
    const cworkItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalCWork(true);
    }

    //Передача функций
    const func = {
        view: viewItemInit,
        edit: editItemInit,
        del: delItemInit,
        order: orderItemInit,
        complaint: complaintItemInit,
        tuser: tuserItemInit,
        cwork: cworkItemInit,
    }


    //Предзагрузчик
    if(isAddsLoading)
    {
        return (
            <Preloader />
        );
    }

    //Вывод
    return (
        <div>

            <div className="btn_right_top_panel">

                {
                    AppAuthStore.executor == 1
                        ?
                        <div className="btn_right_top_panel_part">
                            <input
                                type="checkbox"
                                name="contractor"
                                value={AppAuthStore.user.id}
                                checked={ isChecked(filters, ['contractor', 'v'], AppAuthStore.user.id) }
                                onChange={e => setFilters(checkboxChecked2d(e, filters, 'contractor', 'v', AppAuthStore.user.id, 'null'))}
                            />
                            Закупки на исполнении
                        </div>
                        :
                        <></>
                }

                <div className="btn_right_top_panel_part">
                    <input
                        type="checkbox"
                        name="status-1"
                        value={AppAuthStore.user.id}
                        checked={ isChecked(filters, ['status-1', 'v'], '2,3') }
                        onChange={e => setFilters(checkboxChecked2d(e, filters, 'status-1', 'v', [2, 3], [-2, -3]))}
                    />
                    Закрытые закупки
                </div>

                <RuleAdd>
                    <a href="#"
                       className="btn btn_with_icon btn_modal rule_add"
                       onClick={ e => addItemInit(e) }
                    >
                        <span>&#xf067;</span> Добавить закупку
                    </a>
                </RuleAdd>

            </div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <div className="data_table_div data_table_div_with_filtrs" onMouseDown={e => dragContentHorizontal(e)}>
                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                        <tr>
                            {
                                userFields.map(
                                    (th, index) =>
                                        <PurchasesTh
                                            key={th.name}
                                            field={th}
                                            references={references}
                                            filters={filters}
                                            setFilters={setFilters}
                                        />
                                )
                            }
                            <td>Действия</td>
                        </tr>
                    </thead>
                    <PurchasesList
                        items={items}
                        isLoading={isItemsLoading}
                        func={func}
                        references={references}
                        userFields={userFields}
                    />
                </table>
            </div>

            <Pagination
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />

            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }

            <PurchasesViewForm
                modal={modalView}
                setModal={setModalView}
                tekItemId={tekItemId}
                references={references}
            />

            <PurchasesEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                tekItemId={tekItemId}
                setTekItemId={setTekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
            />

            <PurchasesDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

            <OrdersEditForm
                modal={modalOrder}
                setModal={setModalOrder}
                tekItemId={0}
                setUpdTrigger={setUpdTrigger}
                references={references}
                tekPurchaseId={tekItemId}
            />

            <ComplaintsEditForm
                modal={modalComplaint}
                setModal={setModalComplaint}
                tekItemId={0}
                setUpdTrigger={setUpdTrigger}
                references={references}
                tekPurchaseId={tekItemId}
            />

            <PurchasesTUserForm
                modal={modalTUser}
                setModal={setModalTUser}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
            />

            <PurchasesCWorkForm
                modal={modalCWork}
                setModal={setModalCWork}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
            />

        </div>
    );

};

export default PurchasesContent;