export const dragContentHorizontal = (e) => {

    //
    const elemScroll = e.currentTarget;
    let pageX = 0;


    //
    elemScroll.style.cursor = 'grabbing';
    elemScroll.style.userSelect = 'none';


    //
    document.onmousemove = function (e) {
        if (pageX !== 0)
        {
            elemScroll.scrollLeft = elemScroll.scrollLeft + (pageX - e.pageX);
        }
        pageX = e.pageX;
    }


    //
    elemScroll.onmouseup = function () {

        //
        document.onmousemove = null;
        elemScroll.onmouseup = null;

        //
        elemScroll.style.cursor = 'default';
        elemScroll.style.removeProperty('user-select');
    }


}