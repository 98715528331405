import $api from "../http";

export default class AuthService {

    static async login(username, password) {
        return $api.post('/auth/login', {username, password});
    }

    static async logout() {
        return $api.post('/auth/logout');
    }

    static async refresh() {
        return $api.post('/auth/refresh');
    }

    static async me() {
        return $api.post('/auth/me');
    }

}