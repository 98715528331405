import React from 'react';
import TabMenu from "../../components/TabMenu/TabMenu";
import { Navigate } from "react-router-dom";
import {profileMenu} from "../../menu/ProfileMenu";

const ProfilePage = () => {
    return (
        <div>
            <div id="page_content_title">Мой профиль</div>

            <TabMenu menu={profileMenu}/>

            <Navigate to="/profile/interface" replace />

        </div>
    );
};

export default ProfilePage;