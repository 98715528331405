import React from 'react';
import DepartmentsItem from "./DepartmentsItem";
import {dragContentHorizontal} from "../../../utils/DragContentHorizontal";

const DepartmentsList = ({items, isLoading, func, rolesList}) => {

    if (!items.length && ! isLoading) {
        return (
            <div>
                Записи не найдены!
            </div>
        )
    }

    return (
        <div className="data_table_div" onMouseDown={e => dragContentHorizontal(e)}>

            <table cellPadding="0" cellSpacing="0" border="0">
                <thead>
                <tr>
                    <td>ID</td>
                    <td>Наименование отдела</td>
                    <td>Группа</td>
                    <td>Примечание</td>
                    <td>Действия</td>
                </tr>
                </thead>

                <tbody>
                    {
                        items.map(
                            (item, index) =>
                                <DepartmentsItem
                                    key={item.id}
                                    item={item}
                                    func={func}
                                    rolesList={rolesList}
                                />
                        )
                    }
                </tbody>
            </table>

        </div>
    );
};

export default DepartmentsList;