import React, {useEffect, useState} from 'react';
import SettingsService from "../../../services/SettingsService";
import {errors422} from "../../../utils/Errors422";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {useFetching} from "../../../hooks/useFetching";
import {inputChange} from "../../../utils/InputChange";
import FormInputFile from "../../Interface/FormInputFile";
import UploadService from "../../../services/UploadService";
import {RuleEdit} from "../../Interface/Rule/RuleAction";
import MultiField from "../../Interface/MultiField";
import {scrollToTop} from "../../../utils/ScrollToTop";

const SettingsOrdersForm = () => {

    //Получение настроек
    const [settings, setSettings] = useState({
        orders_type: '',
        orders_torg: '',
        orders_num: '',
        orders_tpl_publication1_title: '',
        orders_tpl_publication1_file: '',
        orders_tpl_publication2_title: '',
        orders_tpl_publication2_file: '',
        orders_tpl_cancel1_title: '',
        orders_tpl_cancel1_file: '',
        orders_tpl_cancel2_title: '',
        orders_tpl_cancel2_file: '',
        orders_tpl_changes1_title: '',
        orders_tpl_changes1_file: '',
        orders_tpl_changes2_title: '',
        orders_tpl_changes2_file: '',
    });

    const [fetchSettings, isLoading, error] = useFetching(
        async () => {
            const response = await SettingsService.getSettings('orders_type,orders_torg,orders_num,orders_tpl_publication1_title,orders_tpl_publication1_file,orders_tpl_publication2_title,orders_tpl_publication2_file,orders_tpl_cancel1_title,orders_tpl_cancel1_file,orders_tpl_cancel2_title,orders_tpl_cancel2_file,orders_tpl_changes1_title,orders_tpl_changes1_file,orders_tpl_changes2_title,orders_tpl_changes2_file');
            setSettings(response.data.data);
        }
    );

    useEffect(() => {
        fetchSettings();
    }, []);


    //Сохранение настроек
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {

        //
        scrollToTop();

        //
        let errArr = [];

        //
        if(errArr.length === 0) {

            //
            try {

                const response = await SettingsService.setSettings(settings);

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);

                    //Обновить статус файлов
                    let tek_form_files = localStorage.getItem('form_files');
                    if(tek_form_files !== '0')
                    {
                        try {
                            const res = await UploadService.updateFiles(tek_form_files, 1);
                            localStorage.setItem('form_files', '0');
                        }
                        catch (e1) {
                            //
                        }
                    }

                }
                else
                {
                    errArr.push('Ошибка сохранения настроек.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения настроек.');
            }

        }

        //
        setErrors(errArr);
    }

    //
    const FILE_SERVER = process.env.REACT_APP_FILE_HOST;

    //
    useEffect(() => {
        localStorage.setItem('form_files', '0');
    }, []);

    //
    return (
        <div>

            <FormSuccess ok={success} text="Настройки успешно сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td colSpan="3"><b>Справочники</b></td>
                    </tr>

                    <tr>
                        <td>
                            Вид приказа
                        </td>
                        <td colSpan="2">
                            <MultiField
                                name="orders_type"
                                value={settings.orders_type}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Наименования<br />торговых площадок
                        </td>
                        <td colSpan="2">
                            <MultiField
                                name="orders_torg"
                                value={settings.orders_torg}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            Начальный номер приказов
                        </td>
                        <td colSpan="2">
                            <input
                                type="text"
                                name="orders_num"
                                value={settings.orders_num}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="3"><b>Приказ на публикацию</b></td>
                    </tr>

                    <tr>
                        <td>Шаблон 1</td>
                        <td>

                            <input
                                type="text"
                                name="orders_tpl_publication1_title"
                                value={settings.orders_tpl_publication1_title}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />

                        </td>
                        <td>

                            <FormInputFile
                                name="orders_tpl_publication1_file"
                                value={settings.orders_tpl_publication1_file}
                                onChange={e => setSettings(inputChange(e, settings))}
                                accept=".docx"
                                server={FILE_SERVER}
                                storage="storage"
                                dir="orders-tpl"
                            />

                        </td>
                    </tr>

                    <tr>
                        <td>Шаблон 2</td>
                        <td>

                            <input
                                type="text"
                                name="orders_tpl_publication2_title"
                                value={settings.orders_tpl_publication2_title}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />

                        </td>
                        <td>

                            <FormInputFile
                                name="orders_tpl_publication2_file"
                                value={settings.orders_tpl_publication2_file}
                                onChange={e => setSettings(inputChange(e, settings))}
                                accept=".docx"
                                server={FILE_SERVER}
                                storage="storage"
                                dir="orders-tpl"
                            />

                        </td>
                    </tr>

                    <tr>
                        <td colSpan="3"><b>Приказ на отмену закупки</b></td>
                    </tr>

                    <tr>
                        <td>Шаблон 1</td>
                        <td>

                            <input
                                type="text"
                                name="orders_tpl_cancel1_title"
                                value={settings.orders_tpl_cancel1_title}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />

                        </td>
                        <td>

                            <FormInputFile
                                name="orders_tpl_cancel1_file"
                                value={settings.orders_tpl_cancel1_file}
                                onChange={e => setSettings(inputChange(e, settings))}
                                accept=".docx"
                                server={FILE_SERVER}
                                storage="storage"
                                dir="orders-tpl"
                            />

                        </td>
                    </tr>

                    <tr>
                        <td>Шаблон 2</td>
                        <td>

                            <input
                                type="text"
                                name="orders_tpl_cancel2_title"
                                value={settings.orders_tpl_cancel2_title}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />

                        </td>
                        <td>

                            <FormInputFile
                                name="orders_tpl_cancel2_file"
                                value={settings.orders_tpl_cancel2_file}
                                onChange={e => setSettings(inputChange(e, settings))}
                                accept=".docx"
                                server={FILE_SERVER}
                                storage="storage"
                                dir="orders-tpl"
                            />

                        </td>
                    </tr>

                    <tr>
                        <td colSpan="3"><b>Приказ на внесение изменений</b></td>
                    </tr>

                    <tr>
                        <td>Шаблон 1</td>
                        <td>

                            <input
                                type="text"
                                name="orders_tpl_changes1_title"
                                value={settings.orders_tpl_changes1_title}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />

                        </td>
                        <td>

                            <FormInputFile
                                name="orders_tpl_changes1_file"
                                value={settings.orders_tpl_changes1_file}
                                onChange={e => setSettings(inputChange(e, settings))}
                                accept=".docx"
                                server={FILE_SERVER}
                                storage="storage"
                                dir="orders-tpl"
                            />

                        </td>
                    </tr>
                    <tr>
                        <td>Шаблон 2</td>
                        <td>

                            <input
                                type="text"
                                name="orders_tpl_changes2_title"
                                value={settings.orders_tpl_changes2_title}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />

                        </td>
                        <td>

                            <FormInputFile
                                name="orders_tpl_changes2_file"
                                value={settings.orders_tpl_changes2_file}
                                onChange={e => setSettings(inputChange(e, settings))}
                                accept=".docx"
                                server={FILE_SERVER}
                                storage="storage"
                                dir="orders-tpl"
                            />

                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <RuleEdit>
                                <input
                                    type="button"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={() => handleSubmit() }
                                    className="rule_edit"
                                />
                            </RuleEdit>
                        </td>
                        <td></td>
                    </tr>

                    </tbody>
                </table>
            </form>

        </div>
    );


};

export default SettingsOrdersForm;