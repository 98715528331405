import React, {useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {inputChange} from "../../../utils/InputChange";
import CommissionsServise from "../../../services/CommissionsServise";
import {errors422} from "../../../utils/Errors422";
import FormSelect from "../../Interface/FormSelect";
import {inputChange2d} from "../../../utils/InputChange2d";
import {dateFormat} from "../../../utils/DateFormat";
import {switchClass} from "../../../utils/SwitchClass";

const CommissionsEditForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {

    //
    let FormTitle = '';

    if(tekItemId === 0)
    {
        FormTitle = 'Добавление комиссии';
    }
    else
    {
        FormTitle = 'Редактирование комиссии';
    }

    //
    const nullItem = {

    };

    //
    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0) {
                setItem(nullItem);
            } else {
                const getItem = async (id) => {
                    const response = await CommissionsServise.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async (e) => {

        //
        e.preventDefault();

        //
        let errArr = [];

        //
        if(item.num === '')
        {
            errArr.push('Поле «Номер состава» не заполнено');
        }

        //
        if(errArr.length === 0)
        {
            try {
                //
                let response;

                if(tekItemId === 0)
                {
                    response = await CommissionsServise.create(item);
                }
                else
                {
                    response = await CommissionsServise.updateById(tekItemId, item);
                }

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения закупки.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения закупки.');
            }

        }

        //
        setErrors(errArr);
    }


    //
    return (
        <ModalWindow title={FormTitle} active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td>Номер состава <span className="zv">*</span></td>
                        <td>
                            <input
                                type="text"
                                name="num"
                                value={item.num || ''}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>ФИО председателя комиссии</td>
                        <td>

                            <input
                                type="text"
                                name="chairman"
                                value={item.chairman || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                className="f_chairman f_chairman_i"
                            />

                            <FormSelect
                                name="chairman"
                                options={references.employeesList}
                                defaultValue="Выберите значение"
                                value={item.chairman || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                valueKey="fio"
                                nameKey="fio"
                                defValue="0"
                                defName=""
                                className="f_chairman f_chairman_s hide"
                            />

                            <div className="switch_field">
                                <span onClick={e => switchClass(e,'.f_chairman', '.f_chairman_i', 'hide', '.f_chairman_sw', 'active')} className="f_chairman_sw active">указать вручную</span>
                                &nbsp;|&nbsp;
                                <span onClick={e => switchClass(e,'.f_chairman', '.f_chairman_s', 'hide', '.f_chairman_sw', 'active')} className="f_chairman_sw">выбрать из списка</span>
                            </div>

                        </td>
                    </tr>

                    <tr>
                        <td>ФИО секретаря комиссии</td>
                        <td>

                            <input
                                type="text"
                                name="secretary"
                                value={item.secretary || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                className="f_secretary f_secretary_i"
                            />

                            <FormSelect
                                name="secretary"
                                options={references.employeesList}
                                defaultValue="Выберите значение"
                                value={item.secretary || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                valueKey="fio"
                                nameKey="fio"
                                defValue="0"
                                defName=""
                                className="f_secretary f_secretary_s hide"
                            />

                            <div className="switch_field">
                                <span onClick={e => switchClass(e,'.f_secretary', '.f_secretary_i', 'hide', '.f_secretary_sw', 'active')} className="f_secretary_sw active">указать вручную</span>
                                &nbsp;|&nbsp;
                                <span onClick={e => switchClass(e,'.f_secretary', '.f_secretary_s', 'hide', '.f_secretary_sw', 'active')} className="f_secretary_sw">выбрать из списка</span>
                            </div>

                        </td>
                    </tr>

                    <tr>
                        <td>ФИО члена комиссии №1</td>
                        <td>

                            <input
                                type="text"
                                name="member1"
                                value={item.member1 || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                className="f_member1 f_member1_i"
                            />

                            <FormSelect
                                name="member1"
                                options={references.employeesList}
                                defaultValue="Выберите значение"
                                value={item.member1 || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                valueKey="fio"
                                nameKey="fio"
                                defValue="0"
                                defName=""
                                className="f_member1 f_member1_s hide"
                            />

                            <div className="switch_field">
                                <span onClick={e => switchClass(e,'.f_member1', '.f_member1_i', 'hide', '.f_member1_sw', 'active')} className="f_member1_sw active">указать вручную</span>
                                &nbsp;|&nbsp;
                                <span onClick={e => switchClass(e,'.f_member1', '.f_member1_s', 'hide', '.f_member1_sw', 'active')} className="f_member1_sw">выбрать из списка</span>
                            </div>

                        </td>
                    </tr>

                    <tr>
                        <td>ФИО члена комиссии №2</td>
                        <td>

                            <input
                                type="text"
                                name="member2"
                                value={item.member2 || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                className="f_member2 f_member2_i"
                            />

                            <FormSelect
                                name="member2"
                                options={references.employeesList}
                                defaultValue="Выберите значение"
                                value={item.member2 || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                valueKey="fio"
                                nameKey="fio"
                                defValue="0"
                                defName=""
                                className="f_member2 f_member2_s hide"
                            />

                            <div className="switch_field">
                                <span onClick={e => switchClass(e,'.f_member2', '.f_member2_i', 'hide', '.f_member2_sw', 'active')} className="f_member2_sw active">указать вручную</span>
                                &nbsp;|&nbsp;
                                <span onClick={e => switchClass(e,'.f_member2', '.f_member2_s', 'hide', '.f_member2_sw', 'active')} className="f_member2_sw">выбрать из списка</span>
                            </div>

                        </td>
                    </tr>

                    <tr>
                        <td>Актуальный состав</td>
                        <td>
                            <FormSelect
                                name="actual_status"
                                options={references.actualsList}
                                defaultValue="Выберите значение"
                                value={typeof item.actual_status !== "undefined" ? item.actual_status : ''}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Актуален с даты</td>
                        <td>
                            <input
                                type="date"
                                name="actual_date"
                                value={item.actual_date ? dateFormat(item.actual_date, 'Y-m-d') : ''}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="submit"
                                name="submit"
                                value="Сохранить"
                                onClick={ e => handleSubmit(e) }
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>

            </form>

        </ModalWindow>
    );
};

export default CommissionsEditForm;