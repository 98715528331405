import $api from "../http";

export default class EmployeesService {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}) {
        return await $api.get('/employees/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
            }
        })
    }

    static async create(data) {
        return await $api.post('/employees/create', data);
    }

    static async getById(id) {
        return await $api.get('/employees/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/employees/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/employees/' + id + '/delete');
    }

    //
    static async getAllHelper(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}) {
        return await $api.get('/employees/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                helper: 1,
            }
        })
    }

    static async getAllExecutionsHelper(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}, status = []) {
        return await $api.get('/employees/executions', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                status: status,
                helper: 1,
            }
        })
    }
}