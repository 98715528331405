import $api from "../http";

export default class PurchasesServise {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}) {
        return await $api.get('/purchases/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
            }
        })
    }

    static async create(data) {
        return await $api.post('/purchases/create', data);
    }

    static async getById(id) {
        return await $api.get('/purchases/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/purchases/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/purchases/' + id + '/delete');
    }

    static async getLast() {
        return await $api.get('/purchases/last', {
            params: {
                helper: 1,
            }
        });
    }

    static async getSearch(field, operator, value) {
        return await $api.get('/purchases/search', {
            params: {
                field: field,
                operator: operator,
                value: value,
                helper: 1,
            }
        });
    }

    static async getByIdWithFields(id, fields) {
        return await $api.get('/purchases/' + id + '/readf', {
            params: {
                fields: fields,
            }
        });
    }

}