import React from 'react';
import {Link, useMatch} from "react-router-dom";

const MainMenuItem = (props) => {
    return (
        <li>
            <Link to={props.to} className={useMatch(props.to) ? 'active' : ''}>
                <span className="pcl_p1">{String.fromCharCode(props.icon)}</span>
                <span className="pcl_p2">{props.title}</span>
            </Link>
        </li>
    );
};

export default MainMenuItem;