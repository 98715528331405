import React from 'react';
import PurchasesItem from "./PurchasesItem";

const PurchasesList = ({items, isLoading, func, references, userFields}) => {

    //
    if (!items.length && ! isLoading) {
        return (
            <tbody>
                <tr>
                    <td colSpan={userFields.length + 1}>Записи не найдены!</td>
                </tr>
            </tbody>
        )
    }

    //
    return (
        <tbody>
            {
                items.map(
                    (item, index) =>
                        <PurchasesItem
                            key={item.id}
                            item={item}
                            func={func}
                            references={references}
                            userFields={userFields}
                        />
                )
            }
        </tbody>
    );
};

export default PurchasesList;