import React from 'react';
import EmployeesContent from "../../components/Pages/Employees/EmployeesContent";

const EmployeesPage = () => {
    return (
        <div>
            <div id="page_content_title">Сотрудники</div>

            <EmployeesContent />

        </div>
    );
};

export default EmployeesPage;