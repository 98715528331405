import React from 'react';
import {getPagesArray} from "../../utils/Pages";

const Pagination = ({totalElems, totalPages, page, changePage, itemsLimit, itemsCount}) => {

    let pagesArray = getPagesArray(totalPages);

    if(totalPages <= 1)
    {
        return '';
    }

    let beg = (page - 1) * itemsLimit + 1;
    let end = (page - 1) * itemsLimit + itemsCount;

    return (
        <div className="paginator">

            <span className="paginator_info">Показано с {beg} по {end} из {totalElems} записей.</span>

            <ul>
                {
                    pagesArray.map(
                        pagesItem =>
                            <li key={pagesItem}>
                                <span
                                    onClick={ () => changePage(pagesItem) }
                                    className={page === pagesItem ? 'active' : ''}
                                >{pagesItem}</span>
                            </li>
                    )
                }
            </ul>

        </div>

    );
};

export default Pagination;