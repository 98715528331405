import React from 'react';
import TabMenu from "../../components/TabMenu/TabMenu";
import {settingsMenu} from "../../menu/SettingsMenu";
import SettingsSmtpForm from "../../components/Pages/Settings/SettingsSmtpForm";

const SettingsSmtpPage = () => {
    return (
        <div>
            <div id="page_content_title">Настройки</div>

            <TabMenu menu={settingsMenu} />

            <SettingsSmtpForm />

        </div>
    );
};

export default SettingsSmtpPage;