export const isChecked = (json, props, value = '1') => {

    if( check(json, props) == value )
    {
        return 'checked';
    }

    return '';
}


//проверка наличия элемента
function check(json, props) {

    if(json == null)
    {
        return 0;
    }

    var arr = json;
    var i, max_i;

    for (i = 0, max_i = props.length; i < max_i; i++) {

        if( arr[ props[i] ] === undefined )
        {
            return 0;
        }
        else
        {
            arr = arr[ props[i] ];
        }

    }
    return arr;
}


//
export const checkboxChecked3d = (event, obj, field1, field2, value1 = 1, value2 = 0) => {

    //
    if(obj[field1] == null)
    {
        obj[field1] = {};
    }

    //
    if( obj[field1][event.target.name] === undefined )
    {
        obj[field1][event.target.name] = {};
    }

    //
    if(event.target.checked === true)
    {
        obj[field1][event.target.name][field2] = value1;
    }
    else
    {
        obj[field1][event.target.name][field2] = value2;
    }

    //
    return {...obj, ...obj};
}

//
export const checkboxChecked2d = (event, obj, field1, field2, value1 = 1, value2 = 0) => {

    //
    if(obj[field1] == null)
    {
        obj[field1] = {};
    }

    //
    if(event.target.checked === true)
    {
        obj[field1][field2] = value1;
    }
    else
    {
        obj[field1][field2] = value2;
    }

    //
    return {...obj, ...obj};
}

//
export const isChecked1d = (val, json) => {

    if(json !== undefined)
    {
        if( json.indexOf(val) !== -1 )
        {
            return 'checked';
        }

    }

    return '';
}

//
export const checkboxChecked1d = (event, obj) => {

    //
    let newValue = [];

    //
    const cbElems = document.querySelectorAll('input[name="' + event.target.name + '"]:checked');

    for (let i = 0; i < cbElems.length; i++) {
        newValue.push( cbElems[i].value );
    }

    //
    return {...obj, ...{[event.target.name]: newValue}};
}