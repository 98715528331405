import React, {useState} from 'react';

const FormSuccess = ({ok, title, text, fading}) => {

    return (

        ok &&
        <div className="form_message form_message_success" fading1={fading ? 'true' : 'false'}>
            <div className="form_message_title">{title ? title : 'Успешно'}</div>
            <div className="form_message_text">{text ? text : 'Данные сохранены.'}</div>
        </div>

    );
};

export default FormSuccess;