import React from 'react';
import RulesContent from "../../components/Pages/Rules/RulesContent";

const RulesPage = () => {
    return (
        <div>
            <div id="page_content_title">Права доступа</div>

            <RulesContent />

        </div>
    );
};

export default RulesPage;