import React from 'react';
import TabMenuItem from "./TabMenuItem";

const TabMenu = (props) => {
    return (
        <div className="horizontal_tabs">
            <ul>
                {
                    props.menu.map(
                        (route, index) =>
                            <TabMenuItem
                                to={route.path}
                                title={route.title}
                                key={index}
                            />
                    )
                }
            </ul>
        </div>
    );
};

export default TabMenu;