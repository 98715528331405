import React from 'react';
import {searchInArray} from "../../../utils/SearchInArray";

const JournalItem = ({item, func, sectionsList, actionsList, usersList}) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{searchInArray(sectionsList, item.section, 'path', 'title')}</td>
            <td>{searchInArray(actionsList, item.action, 'value', 'name')}</td>
            <td>{searchInArray(usersList, item.user_id, 'id', 'fio')}</td>
            <td>{item.time}</td>
        </tr>
    );
};

export default JournalItem;