export const userMenu = [
    {
        title: 'Мой профиль',
        icon: '0xf2be',
        path: 'profile'
    },
    {
        title: 'Выход',
        icon: '0xf08b',
        path: 'logout'
    },
];