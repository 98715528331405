import React from 'react';
import {Route, Routes} from "react-router-dom";
import {privateRoutes, publicRoutes} from "../routes";
import Layout from "../layouts/Layout";

const AppRouter = () => {
    return (
        <Routes>
            {
                publicRoutes.map(
                    (route, index) =>
                        <Route path={route.path} element={route.element} key={index}/>
                )
            }
            <Route path="/" element={<Layout/>} >
                {
                    privateRoutes.map(
                        (route, index) =>
                            <Route path={route.path} element={route.element} key={index}/>
                    )
                }
            </Route>
        </Routes>
    );
};

export default AppRouter;