import React from 'react';

const Preloader = () => {
    return (
        <div className="page_preloader">

        </div>
    );
};

export default Preloader;