import React from 'react';
import UserMenuItem from "./UserMenuItem";
import {userMenu} from "../../menu/UserMenu";

const UserMenu = () => {
    return (
        <div className="site_menu" id="site_user_menu">
            <ul>
                {
                    userMenu.map(
                        (route, index) =>
                            <UserMenuItem
                                to={route.path}
                                icon={route.icon}
                                title={route.title}
                                key={index}
                            />
                    )
                }
            </ul>
        </div>
    );
};

export default UserMenu;