import React from 'react';
import {RuleAdd, RuleDel, RuleEdit, RuleMatchUser, RuleTuser, RuleView} from "../../Interface/Rule/RuleAction";
import PurchasesTd from "./PurchasesTd";

const PurchasesItem = ({item, func, references, userFields}) => {
    return (
        <tr  className={ item.contractor === 0 ? 'no_contractor' : '' }>
            {
                userFields.map(
                    (td, index) =>
                        <PurchasesTd
                            key={td.name}
                            field={td}
                            value={item[td.name]}
                            item={item}
                            references={references}
                        />
                )
            }
            <td>

                <div className="action_link_icon_list">

                    <RuleView>
                        <a href="#"
                           className="action_link_icon action_link_icon_edit rule_edit"
                           title="Просмотреть"
                           onClick={ e => func.view(e, item.id) }
                        >&#xf06e;</a>
                    </RuleView>

                    <RuleEdit>
                        <a href="#"
                           className="action_link_icon action_link_icon_edit rule_edit"
                           title="Редактировать"
                           onClick={ e => func.edit(e, item.id) }
                        >&#xf044;</a>
                    </RuleEdit>

                    <RuleDel>
                        <a href="#"
                           className="action_link_icon action_link_icon_delete rule_del"
                           title="Удалить"
                           onClick={ e => func.del(e, item.id) }
                        >&#xf00d;</a>
                    </RuleDel>

                    <></>

                    {
                        item.status != 2 && item.status != 3 && item.status != 6 && item.status != 7 && item.status != 8
                            ?
                            <>
                                <RuleTuser>
                                    <a href="#"
                                       className="action_link_icon action_link_icon_edit rule_edit"
                                       title="Назначить исполнителя"
                                       onClick={ e => func.tuser(e, item.id) }
                                    >&#xf234;</a>
                                </RuleTuser>

                                <RuleMatchUser valUserId={item.contractor}>
                                    <a href="#"
                                       className="action_link_icon action_link_icon_edit rule_edit"
                                       title="Работа исполнителя"
                                       onClick={ e => func.cwork(e, item.id) }
                                    >&#xf1de;</a>
                                </RuleMatchUser>


                                {
                                    (item.status == 1 && item.orders_count_1 == 0) || (item.status == 5)
                                        ?
                                        <>
                                            <RuleAdd path="orders">
                                                <a href="#"
                                                   className="action_link_icon action_link_icon_edit rule_edit"
                                                   title="Сформировать приказ"
                                                   onClick={ e => func.order(e, item.id) }
                                                >&#xf233;</a>
                                            </RuleAdd>
                                        </>
                                        :
                                        <></>
                                }

                                <RuleAdd path="complaints">
                                    <a href="#"
                                       className="action_link_icon action_link_icon_edit rule_edit"
                                       title="Сформировать жалобу"
                                       onClick={ e => func.complaint(e, item.id) }
                                    >&#xf0a1;</a>
                                </RuleAdd>
                            </>
                            :
                            <></>
                    }

                </div>

            </td>
        </tr>
    );
};

export default PurchasesItem;