import React from 'react';
import PurchasesServise from "../../../services/PurchasesServise";
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";

const PurchasesDelForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {

    //
    const delItemRun = () => {
        setModal(false);
        PurchasesServise.deleteById(tekItemId);
        setUpdTrigger( Date.now() );
    }

    //
    return (
        <ModalWindow title="Удаление закупки" active={modal} setActive={setModal}>

            <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                <tbody>
                    <tr align="center">
                        <td>Вы действительно хотите удалить закупку?</td>
                    </tr>
                    <tr>
                        <td align="center">
                            <input
                                type="submit"
                                name="submit"
                                value="Подтверждаю"
                                onClick={ () => delItemRun() }
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

        </ModalWindow>
    );
};

export default PurchasesDelForm;