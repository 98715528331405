import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import SettingsService from "../../../services/SettingsService";
import {inputChange} from "../../../utils/InputChange";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {errors422} from "../../../utils/Errors422";
import {RuleEdit} from "../../Interface/Rule/RuleAction";
import {scrollToTop} from "../../../utils/ScrollToTop";

const SettingsSmtpForm = () => {

    //Получение настроек
    const [settings, setSettings] = useState({
        smtp_server_name: '',
        smtp_protocol: '',
        smtp_port: '',
        smtp_user: '',
        smtp_pass: '',
    });

    const [fetchSettings, isLoading, error] = useFetching(
        async () => {
            const response = await SettingsService.getSettings('smtp_server_name,smtp_protocol,smtp_port,smtp_user,smtp_pass');
            setSettings(response.data.data);
        }
    );

    useEffect(() => {
        fetchSettings();
    }, []);


    //Сохранение настроек
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {

        //
        scrollToTop();

        //
        let errArr = [];

        //
        if(
            (settings.smtp_server_name === '')
            &&
            (settings.smtp_protocol === '')
            &&
            (settings.smtp_port === '')
            &&
            (settings.smtp_user === '')
            &&
            (settings.smtp_pass === '')
        )
        {
            //
        }
        else
        if(
            (settings.smtp_server_name !== '')
            &&
            (settings.smtp_protocol !== '')
            &&
            (settings.smtp_port !== '')
            &&
            (settings.smtp_user !== '')
            &&
            (settings.smtp_pass !== '')
        )
        {
            //
        }
        else
        if(1 !== 2)
        {
            errArr.push('Заполнены не все поля');
        }

        //
        if(errArr.length === 0) {

            //
            try {
                const response = await SettingsService.setSettings(settings);

                if(response.data.status === 'ok')
                {
                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);
                }
                else
                {
                    errArr.push('Ошибка сохранения настроек.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения настроек.');
            }

        }

        //
        setErrors(errArr);

    }


    //
    return (
        <div>

            <FormSuccess ok={success} text="Настройки успешно сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td colSpan="2"><b>Почта для отправки уведомлений</b></td>
                    </tr>

                    <tr>
                        <td>Имя сервера</td>
                        <td>
                            <input
                                type="text"
                                name="smtp_server_name"
                                value={settings.smtp_server_name}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Протокол</td>
                        <td>
                            <input
                                type="text"
                                name="smtp_protocol"
                                value={settings.smtp_protocol}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Порт</td>
                        <td>
                            <input
                                type="text"
                                name="smtp_port"
                                value={settings.smtp_port}
                                onChange={e => setSettings(inputChange(e, settings))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Имя пользователя</td>
                        <td>
                            <input
                                type="text"
                                name="smtp_user"
                                value={settings.smtp_user}
                                onChange={e => setSettings(inputChange(e, settings))}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Пароль</td>
                        <td>
                            <input
                                type="password"
                                name="smtp_pass"
                                value={settings.smtp_pass}
                                onChange={e => setSettings(inputChange(e, settings))}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <RuleEdit>
                                <input
                                    type="button"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={() => handleSubmit() }
                                    className="rule_edit"
                                />
                            </RuleEdit>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </form>

        </div>
    );
};

export default SettingsSmtpForm;