import React, {useContext, useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import {AuthContext} from "../../../context/AuthContext";
import {purchasesFields} from "../../../configs/PurchasesFields";
import {checkField} from "../../../utils/CheckField";
import {checkFieldEtap} from "../../../utils/СheckFieldEtap";
import PurchasesServise from "../../../services/PurchasesServise";
import {errors422} from "../../../utils/Errors422";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import PurchasesEditTr from "./PurchasesEditTr";

const PurchasesCWorkForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userPurchasesFields = AppAuthStore.purchasesFields;
    let references_mod;

    //Поля пользователя
    let userFields = purchasesFields.filter(
        (field) => checkField(userPurchasesFields, field.name, 'edit')
    );


    //Для формы
    const [item, setItem] = useState({});

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    //Этап полей
    const [FieldEtap, setFieldEtap] = useState('cwork_sNull');
    const [PurchasesMethod, setPurchasesMethod] = useState(0);
    const [nmckSumm, setNmckSumm] = useState(0);

    //Получение данных
    useEffect(() => {

        //
        if(modal)
        {
            //Запрос
            const getItem = async (id) => {
                //Чтение закупки
                const response = await PurchasesServise.getById(id);
                let responseDataItem = response.data.data;
                let responseDataItemActual = {};

                //Текущий данные
                const purchasesStatus = response.data.data.status;
                const tekPurchasesMethod = response.data.data.method;
                const tekNmckSumm = response.data.data.nmck;

                //Текущий этап
                let tekFieldEtap = 'cwork_s' + purchasesStatus;

                //Для статуса "В работе" учет способа размещения
                if(purchasesStatus === 1)
                {
                    tekFieldEtap = tekFieldEtap + '_t' + tekPurchasesMethod;
                }

                //Сохранение текущих данных в состояние
                setFieldEtap(tekFieldEtap);
                setPurchasesMethod(tekPurchasesMethod);
                setNmckSumm(tekNmckSumm);

                //Поля текущего этапа
                userFields.filter(
                    (field) => checkFieldEtap(field.etap, tekFieldEtap)
                ).forEach((field) => {
                    responseDataItemActual[field.name] = responseDataItem[field.name];
                })

                setItem(responseDataItemActual);

                //Доступные типы
                if( (responseDataItemActual.status !== undefined ) && (responseDataItemActual.status === 5 ) )
                {
                    references_mod = references;
                    references_mod.statusList = references_mod.statusList.filter(function(status){
                        return status[0] === '5' || status[0] === '6' || status[0] === '7' || status[0] === '8';
                    });
                }

            }
            getItem(tekItemId);
        }

    }, [modal, modal]);


    //Смена полей
    useEffect(() => {

        if(item.changes_reason > 0)
        {
            setFieldEtap('cwork_s_5_t' + PurchasesMethod);
        }
        else
        {
            setFieldEtap('cwork_s' + item.status);
        }

    }, [item.status, item.changes_reason]);


    //Сохранение данных
    const handleSubmit = async (e) => {

        //
        e.preventDefault();

        //
        let errArr = [];

        /*
        if(item.contractor === 0)
        {
            errArr.push('Поле «Исполнитель» не заполнено');
        }
        */

        //
        if(errArr.length === 0)
        {
            try {
                //Переходит в статус Опубликована если до этого Размещена
                //( (item.status === 4) && (item.eis_num !== '') )
                if( (item.eis_num !== undefined ) && (item.eis_num !== '' ) )
                {
                    item.status = 5;
                }

                //Подсчет процента экономии
                if( (item.winner_price !== undefined ) && (item.winner_price > 0 ) )
                {
                    const percentEconomy = 100 - 100 * ( item.winner_price / nmckSumm )
                    item.percent_economy = percentEconomy.toString();
                }

                //
                const response = await PurchasesServise.updateById(tekItemId, item);

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения закупки.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения закупки.');
            }

        }

        //
        setErrors(errArr);
    }

    //Вывод формы
    return (
        <ModalWindow title="Работа исполнителя" active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    {
                        userFields.filter(
                            (field) => checkFieldEtap(field.etap, FieldEtap)
                        ).map(
                            (tr, index) =>
                                <PurchasesEditTr
                                    key={tr.name}
                                    field={tr}
                                    value={item[tr.name]}
                                    references={references}
                                    item={item}
                                    setItem={setItem}
                                />
                        )
                    }

                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="submit"
                                name="submit"
                                value="Сохранить"
                                onClick={ e => handleSubmit(e) }
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>

            </form>

        </ModalWindow>
    );
};

export default PurchasesCWorkForm;