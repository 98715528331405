import React from 'react';
import DepartmentsContent from "../../components/Pages/Departments/DepartmentsContent";

const DepartmentsPage = () => {
    return (
        <div>
            <div id="page_content_title">Отделы</div>

            <DepartmentsContent />

        </div>
    );
};

export default DepartmentsPage;