import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import ReportsService from "../../../services/ReportsServise";
import Preloader from "../../Interface/Preloader";
import ReportsList from "./ReportsList";


const ReportsContent = () => {

    //Список записей
    const [items, setItems] = useState([])


    //Получение основного списка
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await ReportsService.getAll();
            setItems(response.data.data.items);
        }
    );


    //
    useEffect(() => {
        fetchItems();
    }, []);


    //Передача функций
    const func = {

    }


    //
    return (
        <div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <ReportsList
                items={items}
                isLoading={isItemsLoading}
                func={func}
            />

            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }

        </div>
    );
};

export default ReportsContent;