import React, {useContext} from 'react';
import LeftPanel from "./LeftPanel";
import WorkArea from "./WorkArea";
import {AuthContext} from "../context/AuthContext";
import {observer} from "mobx-react-lite"
import {Navigate} from "react-router-dom";


const Layout = () => {

    const {AppAuthStore} = useContext(AuthContext);

    //
    if(!AppAuthStore.isCheckAuth)
    {
        return '';
    }

    //
    return (

        AppAuthStore.isAuth
            ?
            <div id="wrap_page">
                <LeftPanel />
                <WorkArea />
            </div>
            :
            <Navigate to="/login" replace />

    );
};

export default observer(Layout);