import React from 'react';
import TabMenu from "../../components/TabMenu/TabMenu";
import {settingsMenu} from "../../menu/SettingsMenu";
import {Navigate} from "react-router-dom";

const SettingsPage = () => {

    return (
        <div>
            <div id="page_content_title">Настройки</div>

            <TabMenu menu={settingsMenu} />

            <Navigate to="/settings/purchases" replace />

        </div>
    );
};

export default SettingsPage;