import React, {useEffect, useState, useContext} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import {inputChange} from "../../../utils/InputChange";
import EmployeesService from "../../../services/EmployeesService";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {errors422} from "../../../utils/Errors422";
import FormSelect from "../../Interface/FormSelect";
import {AuthContext} from "../../../context/AuthContext";

const EmployeesEditForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);

    //
    let FormTitle = '';

    if(tekItemId === 0)
    {
        FormTitle = 'Добавление сотрудника';
    }
    else
    {
        FormTitle = 'Редактирование сотрудника';
    }


    //
    const nullItem = {
        username: '',
        fio: '',
        password: '',
        password_confirmation: '',
        email: '',
        phone: '',
        position_id: 0,
        department_id: 0,
        rule_id: 0,
        executor: 0,
        note: '',
    };


    //
    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0) {
                setItem(nullItem);
            } else {
                const getItem = async (id) => {
                    const response = await EmployeesService.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async (e) => {

        //
        e.preventDefault();


        //
        let errArr = [];


        //
        if(item.username === '')
        {
            errArr.push('Поле «Логин» не заполнено');
        }

        //
        if(tekItemId === 0)
        {
            if (item.password === '')
            {
                errArr.push('Поле «Пароль» не заполнено');
            }

            if (item.password_confirmation === '')
            {
                errArr.push('Поле «Подтверждение пароля» не заполнено');
            }
        }

        //
        if(item.fio === '')
        {
            errArr.push('Поле «Ф.И.О.» не заполнено');
        }


        //
        if(errArr.length === 0)
        {
            try {
                //
                let response;

                if(tekItemId === 0)
                {
                    response = await EmployeesService.create(item);
                }
                else
                {
                    response = await EmployeesService.updateById(tekItemId, item);
                }

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    if(AppAuthStore.user.id === tekItemId)
                    {
                        AppAuthStore.setExecutor(item.executor);
                    }

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения сотрудника.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения сотрудника.');
            }

        }


        //
        setErrors(errArr);
    }

    //
    return (
        <ModalWindow title={FormTitle} active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td>Логин <span className="zv">*</span></td>
                        <td>
                            <input
                                type="text"
                                name="username"
                                value={item.username}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Пароль {tekItemId === 0 ? <span className="zv">*</span> : <></>}</td>
                        <td>
                            <input
                                type="password"
                                name="password"
                                value={item.password || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Подтверждение пароля {tekItemId === 0 ? <span className="zv">*</span> : <></>}</td>
                        <td>
                            <input
                                type="password"
                                name="password_confirmation"
                                value={item.password_confirmation || ''}
                                onChange={e => setItem(inputChange(e, item))}
                                autoComplete="off"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>ФИО <span className="zv">*</span></td>
                        <td>
                            <input
                                type="text"
                                name="fio"
                                value={item.fio}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>E-mail</td>
                        <td>
                            <input
                                type="text"
                                name="email"
                                value={item.email || ''}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Телефон</td>
                        <td>
                            <input
                                type="text"
                                name="phone"
                                value={item.phone || ''}
                                onChange={e => setItem(inputChange(e, item))}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Отдел</td>
                        <td>
                            <FormSelect
                                name="department_id"
                                options={references.departmentsList}
                                defaultValue="Выберите значение"
                                value={item.department_id}
                                onChange={ e => setItem(inputChange(e, item)) }
                                valueKey="id"
                                nameKey="name"
                                defValue="0"
                                defName=""
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Должность</td>
                        <td>
                            <FormSelect
                                name="position_id"
                                options={references.positionsList}
                                defaultValue="Выберите значение"
                                value={item.position_id}
                                onChange={ e => setItem(inputChange(e, item)) }
                                valueKey="0"
                                nameKey="1"
                                defValue="0"
                                defName=""
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Группа</td>
                        <td>
                            <FormSelect
                                name="rule_id"
                                options={references.rolesList}
                                defaultValue="Выберите значение"
                                value={item.rule_id}
                                onChange={ e => setItem(inputChange(e, item)) }
                                valueKey="id"
                                nameKey="name"
                                defValue="0"
                                defName=""
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Исполнитель</td>
                        <td>
                            <FormSelect
                                name="executor"
                                options={references.NoYesList}
                                defaultValue="Выберите значение"
                                value={item.executor}
                                onChange={ e => setItem(inputChange(e, item)) }
                                valueKey="0"
                                nameKey="1"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Примечание</td>
                        <td>
                        <textarea
                            name="note"
                            value={item.note || ''}
                            onChange={e => setItem(inputChange(e, item))}
                        ></textarea>
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <input
                                type="submit"
                                name="submit"
                                value="Сохранить"
                                onClick={ e => handleSubmit(e) }
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>

            </form>

        </ModalWindow>
    );




};

export default EmployeesEditForm;