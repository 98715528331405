import React from 'react';
import ComplaintsItem from "./ComplaintsItem";

const ComplaintsList = ({items, isLoading, func, references}) => {

    //
    if (!items.length && ! isLoading) {
        return (
            <tbody>
                <tr>
                    <td colSpan="14">Записи не найдены!</td>
                </tr>
            </tbody>
        )
    }

    //
    return (
        <tbody>
        {
            items.map(
                (item, index) =>
                    <ComplaintsItem
                        key={item.id}
                        item={item}
                        func={func}
                        references={references}
                    />
            )
        }
        </tbody>
    );
};

export default ComplaintsList;