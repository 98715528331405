import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from './App';
import {AuthContext} from "./context/AuthContext";
import AuthStore from "./store/AuthStore";

import "./assets/css/style.css";
import "./assets/css/login.css";

const AppAuthStore = new AuthStore();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthContext.Provider value={{AppAuthStore}}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AuthContext.Provider>
);