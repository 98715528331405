import {dateFormat} from "./DateFormat";
import {searchInArray} from "./SearchInArray";

export const getPurchasesFieldValue = (field, value, references, item) => {

    let fieldValue = value;
    let param = [];

    switch (field.type) {

        case 'date':
            fieldValue = dateFormat(value, field.definition);
            break;

        case 'ref':
            param = field.definition;
            param = param.split(':');
            fieldValue = searchInArray(references[param[0]], value, param[1], param[2]);
            break;

        case 'relation':

            param = field.definition;
            param = param.split(':');

            fieldValue = '';

            if(item[param[0]] !== undefined)
            {
                const fieldValueObj = item[param[0]];
                const rTpl = param[3];
                let rRes = '';
                let rVal = '';
                let fieldValueArr = [];

                if(fieldValueObj.length > 0)
                {
                    let fieldValueObjF = fieldValueObj;

                    if(param[1] !== '')
                    {
                        fieldValueObjF = fieldValueObj.filter(
                            (obj) => obj[param[1]] == param[2]
                        );
                    }

                    fieldValueObjF.forEach((obj) => {

                        rRes = rTpl;

                        for (var key in obj) {

                            rVal = obj[key];

                            if( (typeof rVal === 'string') && (rVal.indexOf('00:00:00') > 0) )
                            {
                                rVal = dateFormat(rVal, 'd.m.Y');
                            }

                            rRes = rRes.replace(key, rVal);
                        }

                        fieldValueArr.push(rRes);
                    })

                    fieldValue = fieldValueArr.join(', ');
                }
            }

            break;

        default:
        //
    }

    return fieldValue;

}