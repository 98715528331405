import React, {useEffect, useState} from 'react';
import EmployeesService from "../../../services/EmployeesService";
import {useFetching} from "../../../hooks/useFetching";
import JournalsService from "../../../services/JournalsService";
import {getPageCount} from "../../../utils/Pages";
import Preloader from "../../Interface/Preloader";
import Pagination from "../../Interface/Pagination";
import JournalList from "./JournalList";
import {inputChange2d} from "../../../utils/InputChange2d";
import FormSelect from "../../Interface/FormSelect";
import {mainMenu} from "../../../menu/MainMenu";
import {dragContentHorizontal} from "../../../utils/DragContentHorizontal";


const JournalContent = () => {

    //
    const [usersList, setUsersList] = useState([]);

    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(localStorage.getItem('config__records_count_journal'));

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [filters, setFilters] = useState({
        id: {v:'', t:'='},
        section: {v:'null', t:'='},
        action: {v:'null', t:'='},
        user_id: {v:'null', t:'='},
        time: {v:'', t:'%'},
    });


    //
    const sectionsList = mainMenu.filter(
        (menu) => menu.path !== ''
    );
    sectionsList.push({
        path: 'profile',
        title: 'Мой профиль'
    });

    //
    const actionsList = [
        {value: 'view', name: 'Просмотр'},
        {value: 'add', name: 'Добавление'},
        {value: 'edit', name: 'Редактирование'},
        {value: 'del', name: 'Удаление'},
    ];

    //
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            const response1 = await EmployeesService.getAllHelper();
            setUsersList(response1.data.data.items);
        }
    );

    useEffect(() => {
        fetchAdds();
    }, []);


    //
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await JournalsService.getAll(page, limit, 'id', 'desc', filters);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );

    //
    useEffect(() => {
        setPage(1);
    }, [
        filters,
        filters
    ]);

    //
    useEffect(() => {
        fetchItems(page, limit);
    }, [
        page,
        limit,
        filters
    ]);

    //
    const changePage = (page) => {
        setPage(page)
    }


    //Передача функций
    const func = {

    }


    //
    if(isAddsLoading)
    {
        return (
            <Preloader />
        );
    }


    //
    return (
        <div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <div className="data_table_div data_table_div_with_filtrs" onMouseDown={e => dragContentHorizontal(e)}>
                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                        <tr>
                            <td>
                                ID
                                <div className="page_filtr_intable">
                                    <div>
                                        <input
                                            type="text"
                                            name="id"
                                            value={filters.id.v}
                                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                Раздел
                                <div className="page_filtr_intable">
                                    <div>
                                        <FormSelect
                                            name="section"
                                            options={sectionsList}
                                            defaultValue="Выберите значение"
                                            value={filters.section.v}
                                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                            valueKey="path"
                                            nameKey="title"
                                            defValue="null"
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                Действие
                                <div className="page_filtr_intable">
                                    <div>
                                        <FormSelect
                                            name="action"
                                            options={actionsList}
                                            defaultValue="Выберите значение"
                                            value={filters.action.v}
                                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                            defValue="null"
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                Пользователь
                                <div className="page_filtr_intable">
                                    <div>
                                        <FormSelect
                                            name="user_id"
                                            options={usersList}
                                            defaultValue="Выберите значение"
                                            value={filters.user_id.v}
                                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                            valueKey="id"
                                            nameKey="fio"
                                            defValue="null"
                                        />
                                    </div>
                                </div>
                            </td>
                            <td>
                                Время
                                <div className="page_filtr_intable">
                                    <div>
                                        <input
                                            type="text"
                                            name="time"
                                            value={filters.time.v}
                                            onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <JournalList
                        items={items}
                        isLoading={isItemsLoading}
                        func={func}
                        sectionsList={sectionsList}
                        actionsList={actionsList}
                        usersList={usersList}
                    />
                </table>
            </div>

            <Pagination
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />

            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }

        </div>
    );
};

export default JournalContent;