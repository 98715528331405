import React, {useEffect, useContext}  from 'react';
import {matchRoutes, useLocation} from "react-router-dom";
import {privateRoutes} from "../../../routes";
import {AuthContext} from "../../../context/AuthContext";


//
export const RuleView = ({children, path}) => {
    return (
        <RuleAction action="view" path={path}>
            {children}
        </RuleAction>
    );
};


//
export const RuleAdd = ({children, path}) => {
    return (
        <RuleAction action="add" path={path}>
            {children}
        </RuleAction>
    );
};


//
export const RuleEdit = ({children, path}) => {
    return (
        <RuleAction action="edit" path={path}>
            {children}
        </RuleAction>
    );
};


//
export const RuleDel = ({children, path}) => {
    return (
        <RuleAction action="del" path={path}>
            {children}
        </RuleAction>
    );
};


//
export const RuleTuser = ({children, path}) => {
    return (
        <RuleAction action="tuser" path={path}>
            {children}
        </RuleAction>
    );
};


//
const checkAction = (obj, pach, action) => {

    if(obj[pach] === undefined)
    {
        return false;
    }
    else if(obj[pach][action] === undefined)
    {
        return false;
    }
    else if(obj[pach][action] === '0')
    {
        return false;
    }

    return true;
}

export const RuleAction = ({children, action, path}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const rules = AppAuthStore.rules;

    //
    const location = useLocation();

    //Если раздел не указан
    if(path === undefined)
    {
        //Получение текущего раздела
        let [{ route }] = matchRoutes(privateRoutes, location);
        const routePath = route.path;
        const routePathArr = routePath.split('/');
        path = routePathArr[0];
    }

    //Проверка действия
    const checkActionFlag = checkAction(rules, path, action);

    //
    return (

        checkActionFlag
            ?
            <>
                {children}
            </>
            :
            <></>

    );
};


//
export const RuleMatchUser = ({children, valUserId}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const tekUserId = AppAuthStore.user.id;

    //
    return (

        tekUserId === valUserId
            ?
            <>
                {children}
            </>
            :
            <></>

    );
};


export default RuleAction;