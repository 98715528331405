import $api from "../http";

export default class CommissionsServise {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}) {
        return await $api.get('/commissions/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
            }
        })
    }

    static async create(data) {
        return await $api.post('/commissions/create', data);
    }

    static async getById(id) {
        return await $api.get('/commissions/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/commissions/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/commissions/' + id + '/delete');
    }

    //
    static async getAllHelper(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = { actual_status: {v:1, t:'='} }) {
        return await $api.get('/commissions/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
                helper: 1,
            }
        })
    }

}