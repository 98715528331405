import React from 'react';

const MultiField = ({name, value, onChange, heads}) => {

    //
    const headsArr = heads.split('|');


    //Обновление поля
    const field_update = (action = {}) => {

        let objValueTable = [];
        let objValueTr = [];
        let objValueTd = '';

        let listTr = document.querySelectorAll('.multifield[name="' + name + '"] .multifield__tbl_list tr');

        listTr.forEach(function(elemTr, numTr) {

            objValueTr = [];
            let listTd = elemTr.querySelectorAll('td[contenteditable="true"]');

            listTd.forEach(function(elemTd, numTd){
                objValueTd = elemTd.innerHTML;
                objValueTr.push(objValueTd);
            });

            objValueTable.push(objValueTr);
        });

        //
        if(action.add === 1) {

            let objValueTrAdd = [];

            for (let i = 0; i < headsArr.length; i++) {
                objValueTrAdd.push('');
            }

            objValueTable.push(objValueTrAdd);
        }

        //
        if(action.del >= 0) {
            objValueTable.splice(action.del, 1)
        }

        //
        let textareaElem = document.querySelector('.multifield[name="' + name + '"] textarea');
        let textareaElemValue = JSON.stringify(objValueTable);
        textareaElem.value = textareaElemValue;

        //
        let e = {
            target: {
                name: name,
                value: textareaElemValue
            }
        };

        onChange(e);

    }


    //Добавить позицию
    const add_tr = (e) => {
        e.preventDefault();

        field_update({add: 1});
    }

    //Удалить позицию
    const del_tr = (e, trIndex) => {
        e.preventDefault();
        field_update({del: trIndex});
    }

    //Редактирование ячеек
    const keyup_td = (e) => {
        //

        field_update();
    }


    //Очистка вставки
    function escape_text(text)
    {
        var map = {'&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#039;'};
        return text.replace(/[&<>"']/g, function(m) {
            return map[m];
        });
    }

    const paste_td = (e) => {
        //
        e.preventDefault();
        var text = (e.originalEvent || e).clipboardData.getData('text/plain');
        document.execCommand('insertHtml', false, escape_text(text));

        //
        field_update();
    }




    //
    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    //
    let valueObj = [];
    if(isJsonString(value))
    {
        valueObj = JSON.parse(value);
    }


    //
    return (

        <div className="multifield" name={name}>

            <textarea name={name} value={value} onChange={onChange}></textarea>

            <div className="data_table_div">

                <table className="multifield__tbl" cellSpacing="0" cellPadding="0" border="0">

                    <thead>
                    <tr>
                        {
                            headsArr.map(
                            (head, index) =>
                                <td key={index}>{head}</td>
                            )
                        }
                        <td></td>
                    </tr>
                    </thead>

                    <tbody className="multifield__tbl_list">
                    {
                        valueObj.map(
                            (trE, trI) =>
                                <tr key={trI}>
                                    {
                                        trE.map(
                                            (tdE, tdI) =>
                                                <td key={tdI} align="left" contentEditable="true" suppressContentEditableWarning = {true} onBlur={ e => keyup_td(e) } onPaste={ e => paste_td(e) }>{tdE}</td>
                                        )
                                    }
                                    <td width="20">
                                        <div className="multifield__tbl_del" onClick={ e => del_tr(e, trI) }>&#xf00d;</div>
                                    </td>
                                </tr>
                        )
                    }
                    </tbody>

                </table>

                <div className="multifield__add">
                    <a className="multifield__add_link" href="" onClick={ e => add_tr(e) }>добавить позицию</a>
                </div>

            </div>


        </div>
    );
};

export default MultiField;