import React from 'react';

const ReportsItem = ({item, func, references}) => {
    return (
        <div className="data_elem_item">
            <div className="block_panel">
                <div className="block_panel_head">
                    <span>&#xf080;</span>{item.name}
                </div>
                <div className="block_panel_body">
                    <div className="data_elem_item_desc">
                        {item.note}
                    </div>
                    <div className="action_link_list">

                        <a href="reports"
                           className="ajax_url action_link action_link_edit"><span>&#xf044;</span>Сформировать отчет</a>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default ReportsItem;