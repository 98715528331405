import React from 'react';
import ComplaintsContent from "../../components/Pages/Complaints/ComplaintsContent";

const ComplaintsPage = () => {
    return (
        <div>
            <div id="page_content_title">Жалобы</div>

            <ComplaintsContent />

        </div>
    );
};

export default ComplaintsPage;