import React, {useContext} from 'react';
import {AuthContext} from "../../context/AuthContext";
import {observer} from "mobx-react-lite"


const UserInformer = () => {

    const {AppAuthStore} = useContext(AuthContext);

    return (
        <div id="site_user">
            <span>{AppAuthStore.user.fio}</span>
        </div>
    );
};

export default observer(UserInformer);