import React, {useContext} from 'react';
import MainMenuItem from "./MainMenuItem";
import {mainMenu} from "../../menu/MainMenu";
import {AuthContext} from "../../context/AuthContext";
import {observer} from "mobx-react-lite"


const viewMenuItem = (obj, pach) => {

    if(pach !== '')
    {
        if(obj[pach] === undefined)
        {
            return false;
        }
        else if(obj[pach]['view'] === undefined)
        {
            return false;
        }
        else if(obj[pach]['view'] === '0')
        {
            return false;
        }
    }

    return true;
}

const MainMenu = () => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const rules = AppAuthStore.rules;

    //
    return (
        <div className="site_menu" id="site_menu">
            <ul>
                {
                    mainMenu.filter(
                        (menu) => viewMenuItem(rules, menu.path)
                    ).map(
                        (route, index) =>
                            <MainMenuItem
                                to={route.path}
                                icon={route.icon}
                                title={route.title}
                                key={index}
                            />
                    )
                }
            </ul>
        </div>
    );
};

export default observer(MainMenu);