import React from 'react';
import {inputChange2d} from "../../../utils/InputChange2d";
import FormSelect from "../../Interface/FormSelect";

const PurchasesTh = ({field, references, filters, setFilters}) => {

    //
    let paramStr = '';
    let param = [];

    switch (field.type) {

        case 'date':
            //
            break;

        case 'ref':
            paramStr = field.definition;
            param = paramStr.split(':');
            break;

        default:
            //
    }

    //
    return (
        <td>
            {field.title}
            <div className="page_filtr_intable">
                <div>

                    {
                        field.type === '' || field.type === 'textarea'
                            ?
                            <input
                                type="text"
                                name={field.name}
                                value={filters[field.name].v}
                                onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                autoComplete="off"
                            />
                            :
                            <></>
                    }

                    {
                        field.type === 'date'
                            ?
                            <input
                                type="date"
                                name={field.name}
                                value={filters[field.name].v}
                                onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                autoComplete="off"
                            />
                            :
                            <></>
                    }

                    {
                        field.type === 'ref'
                            ?
                            <FormSelect
                                name={field.name}
                                options={references[param[0]]}
                                defaultValue="Выберите значение"
                                value={filters[field.name].v}
                                onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                valueKey={param[1]}
                                nameKey={param[2]}
                                defValue="null"
                            />
                            :
                            <></>
                    }


                </div>
            </div>
        </td>
    );
};

export default PurchasesTh;