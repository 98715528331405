import React from 'react';
import ActionsWidgetContent from "./ActionsWidgetContent";

const ActionsWidget = () => {
    return (
        <div className="data_elem_item data_elem_item_col1">
            <div className="block_panel">
                <div className="block_panel_head">
                    <span>&#xf017;</span>Последние действия
                </div>
                <div className="block_panel_body">
                    <div className="data_elem_item_desc">
                        <ActionsWidgetContent />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ActionsWidget;