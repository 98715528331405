import React, {useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import {inputChange} from "../../../utils/InputChange";
import DepartmentsService from "../../../services/DepartmentsService";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {errors422} from "../../../utils/Errors422";
import FormSelect from "../../Interface/FormSelect";

const DepartmentsEditForm = ({modal, setModal, tekItemId, setUpdTrigger, rolesList}) => {

    //
    let FormTitle = '';

    if(tekItemId === 0)
    {
        FormTitle = 'Добавление отдела';
    }
    else
    {
        FormTitle = 'Редактирование отдела';
    }


    //
    const nullItem = {
        name: '',
        rule_id: 0,
        note: ''
    };


    //
    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0) {
                setItem(nullItem);
            } else {
                const getItem = async (id) => {
                    const response = await DepartmentsService.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async () => {
        //
        let errArr = [];


        //
        if(item.name === '')
        {
            errArr.push('Поле «Наименование отдела» не заполнено');
        }


        //
        if(errArr.length === 0)
        {
            try {
                //
                let response;

                if(tekItemId === 0)
                {
                    response = await DepartmentsService.create(item);
                }
                else
                {
                    response = await DepartmentsService.updateById(tekItemId, item);
                }

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения отдела.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения отдела.');
            }

        }


        //
        setErrors(errArr);
    }

    //
    return (
        <ModalWindow title={FormTitle} active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                <tbody>
                <tr>
                    <td>Наименование отдела <span className="zv">*</span></td>
                    <td>
                        <input
                            type="text"
                            name="name"
                            value={item.name}
                            onChange={e => setItem(inputChange(e, item))}
                        />
                    </td>
                </tr>

                <tr>
                    <td>Группа</td>
                    <td>
                        <FormSelect
                            name="rule_id"
                            options={rolesList}
                            defaultValue="Выберите значение"
                            value={item.rule_id}
                            onChange={ e => setItem(inputChange(e, item)) }
                            valueKey="id"
                            nameKey="name"
                            defValue="0"
                            defName=""
                        />
                    </td>
                </tr>

                <tr>
                    <td>Примечание</td>
                    <td>
                        <textarea
                            name="note"
                            value={item.note || ''}
                            onChange={e => setItem(inputChange(e, item))}
                        ></textarea>
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td>
                        <input
                            type="submit"
                            name="submit"
                            value="Сохранить"
                            onClick={ () => handleSubmit() }
                        />
                    </td>
                </tr>
                </tbody>
            </table>

        </ModalWindow>
    );




};

export default DepartmentsEditForm;