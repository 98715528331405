import React, {useContext} from 'react';
import {Outlet} from "react-router-dom";

import {observer} from "mobx-react-lite"
import {AuthContext} from "../context/AuthContext";
import AccessDeniedPage from "../pages/AccessDeniedPage";


const WorkArea = () => {

    const {AppAuthStore} = useContext(AuthContext);
    //AppAuthStore.checkAccess();

    return (


        <div id="page_right">

            <div id="page_content">
                {
                    AppAuthStore.isAccess
                        ?
                        <Outlet/>
                        :
                        <AccessDeniedPage/>
                }
            </div>

        </div>
    );
};

export default observer(WorkArea);