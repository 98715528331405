import React, {useEffect, useState, useContext} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import EmployeesService from "../../../services/EmployeesService";
import Preloader from "../../Interface/Preloader";
import EmployeesList from "./EmployeesList";
import Pagination from "../../Interface/Pagination";
import {getPageCount} from "../../../utils/Pages";
import EmployeesDelForm from "./EmployeesDelForm";
import EmployeesEditForm from "./EmployeesEditForm";
import {inputChange2d} from "../../../utils/InputChange2d";
import RulesService from "../../../services/RulesService";
import SettingsService from "../../../services/SettingsService";
import DepartmentsService from "../../../services/DepartmentsService";
import FormSelect from "../../Interface/FormSelect";
import {RuleAdd} from "../../Interface/Rule/RuleAction";
import {dragContentHorizontal} from "../../../utils/DragContentHorizontal";


const EmployeesContent = () => {

    //
    const [departmentsList, setDepartmentsList] = useState([]);
    const [positionsList, setPositionsList] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(localStorage.getItem('config__records_count_employees'));

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    const [modalEdit, setModalEdit] = useState(false);
    const [modalDel, setModalDel] = useState(false);

    const [filters, setFilters] = useState({
        id: {v:'', t:'='},
        username: {v:'', t:'%'},
        fio: {v:'', t:'%'},
        email: {v:'', t:'%'},
        phone: {v:'', t:'%'},
        department_id: {v:'null', t:'='},
        position_id: {v:'null', t:'='},
        role_id: {v:'null', t:'='},
        executor: {v:'null', t:'='},
        note: {v:'', t:'%'}
    });


    //
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            const response1 = await DepartmentsService.getAllHelper();
            setDepartmentsList(response1.data.data.items);

            const response2 = await SettingsService.getSettingsHelper('employees_positions');
            setPositionsList(JSON.parse(response2.data.data.employees_positions));

            const response3 = await RulesService.getAllHelper();
            setRolesList(response3.data.data.items);
        }
    );

    useEffect(() => {
        fetchAdds();
    }, []);


    //Нет/Да
    const NoYesList = [
        ['0', 'Нет'],
        ['1', 'Да'],
    ];

    //Передача доп. списков
    const references = {
        departmentsList: departmentsList,
        positionsList: positionsList,
        rolesList: rolesList,
        NoYesList: NoYesList,
    };


    //
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await EmployeesService.getAll(page, limit, 'id', 'asc', filters);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );


    //
    useEffect(() => {
        setPage(1);
    }, [
        filters,
        filters
    ]);


    //
    useEffect(() => {
        fetchItems(page, limit);
    }, [
        page,
        limit,
        updTrigger,
        filters
    ]);


    //
    const changePage = (page) => {
        setPage(page)
    }


    //Добавление записи
    const addItemInit = (e) => {
        e.preventDefault();
        setTekItemId(0);
        setModalEdit(true);
    }


    //Редактирование записи
    const editItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalEdit(true);
    }


    //Удаление записи
    const delItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDel(true);
    }


    //Передача функций
    const func = {
        edit: editItemInit,
        del: delItemInit
    }


    //
    if(isAddsLoading)
    {
        return (
            <Preloader />
        );
    }


    //
    return (
        <div>

            <div className="btn_right_top_panel">

                <RuleAdd>
                    <a href="#"
                       className="btn btn_with_icon btn_modal rule_add"
                       onClick={ e => addItemInit(e) }
                    >
                        <span>&#xf067;</span> Добавить сотрудника
                    </a>
                </RuleAdd>

            </div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <div className="data_table_div data_table_div_with_filtrs" onMouseDown={e => dragContentHorizontal(e)}>
                <table cellPadding="0" cellSpacing="0" border="0">
                    <thead>
                    <tr>
                        <td>
                            ID
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="id"
                                        value={filters.id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Логин
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="username"
                                        value={filters.username.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Ф.И.О.
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="fio"
                                        value={filters.fio.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            E-mail
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="email"
                                        value={filters.email.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Телефон
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="phone"
                                        value={filters.phone.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Отдел
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="department_id"
                                        options={departmentsList}
                                        defaultValue="Выберите значение"
                                        value={filters.department_id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="id"
                                        nameKey="name"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Должность
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="position_id"
                                        options={positionsList}
                                        defaultValue="Выберите значение"
                                        value={filters.position_id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="0"
                                        nameKey="1"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            Группа
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="role_id"
                                        options={rolesList}
                                        defaultValue="Выберите значение"
                                        value={filters.role_id.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="id"
                                        nameKey="name"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>

                        <td>
                            Исполнитель
                            <div className="page_filtr_intable">
                                <div>
                                    <FormSelect
                                        name="executor"
                                        options={NoYesList}
                                        defaultValue="Выберите значение"
                                        value={filters.executor.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        valueKey="0"
                                        nameKey="1"
                                        defValue="null"
                                    />
                                </div>
                            </div>
                        </td>

                        <td>
                            Примечание
                            <div className="page_filtr_intable">
                                <div>
                                    <input
                                        type="text"
                                        name="note"
                                        value={filters.note.v}
                                        onChange={e => setFilters(inputChange2d(e, filters, 'v'))}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </td>
                        <td>Действия</td>
                    </tr>
                    </thead>

                    <EmployeesList
                        items={items}
                        isLoading={isItemsLoading}
                        func={func}
                        references={references}
                    />

                </table>
            </div>


            <Pagination
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />

            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }

            <EmployeesDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

            <EmployeesEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                references={references}
            />

        </div>
    );
};

export default EmployeesContent;