export const profileMenu = [
    {
        title: 'Настройки интерфейса',
        path: 'profile/interface'
    },
    {
        title: 'Личные данные',
        path: 'profile/personal'
    },
    {
        title: 'Смена пароля',
        path: 'profile/password'
    },
];