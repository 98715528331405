import React from 'react';
import ReportsItem from "./ReportsItem";

const ReportsList = ({items, isLoading, func, references}) => {

    //
    if (!items.length && ! isLoading) {
        return (
            <div>
                Записи не найдены!
            </div>
        )
    }

    //
    return (
        <div id="data_elem_list">
            {
                items.map(
                    (item, index) =>
                        <ReportsItem
                            key={item.id}
                            item={item}
                            func={func}
                        />
                )
            }
        </div>
    );
};

export default ReportsList;