import React from 'react';
import EmployeesService from "../../../services/EmployeesService";
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";

const EmployeesDelForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {

    //
    const delItemRun = () => {
        setModal(false);
        EmployeesService.deleteById(tekItemId);
        setUpdTrigger( Date.now() );
    }

    //
    return (
        <ModalWindow title="Удаление сотрудника" active={modal} setActive={setModal}>

            <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                <tbody>
                <tr align="center">
                    <td>Вы действительно хотите удалить сотрудника?</td>
                </tr>
                <tr>
                    <td align="center">
                        <input
                            type="submit"
                            name="submit"
                            value="Подтверждаю"
                            onClick={ () => delItemRun() }
                        />
                    </td>
                </tr>
                </tbody>
            </table>

        </ModalWindow>
    );
};

export default EmployeesDelForm;