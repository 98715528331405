import React from 'react';
import {searchInArray} from "../../../utils/SearchInArray";
import {RuleDel, RuleEdit} from "../../Interface/Rule/RuleAction";


const EmployeesItem = ({item, func, references}) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.username}</td>
            <td>{item.fio}</td>
            <td>{item.email}</td>
            <td>{item.phone}</td>
            <td>{searchInArray(references.departmentsList, item.department_id, 'id', 'name')}</td>
            <td>{searchInArray(references.positionsList, item.position_id, 0, 1)}</td>
            <td>{searchInArray(references.rolesList, item.rule_id, 'id', 'name')}</td>
            <td>{searchInArray(references.NoYesList, item.executor, 0, 1)}</td>
            <td>{item.note}</td>
            <td>
                <div className="action_link_icon_list">

                    <RuleEdit>
                        <a href="#"
                           className="action_link_icon action_link_icon_edit rule_edit"
                           title="Редактировать"
                           onClick={ e => func.edit(e, item.id) }
                        >&#xf044;</a>
                    </RuleEdit>

                    <RuleDel>
                        <a href="#"
                           className="action_link_icon action_link_icon_delete rule_del"
                           title="Удалить"
                           onClick={ e => func.del(e, item.id) }
                        >&#xf00d;</a>
                    </RuleDel>

                </div>
            </td>
        </tr>
    );
};

export default EmployeesItem;