export const searchInArray = (array, value, searchKey, resultKey) => {
    let result = '';

    for( var i = 0, len = array.length; i < len; i++ ) {
        if( array[i][searchKey] == value ) {
            result = array[i][resultKey];
            break;
        }
    }

    return result;
}

export const searchInArrayJson = (array, jsonValue, searchKey, resultKey) => {
    let result = '';
    let resultArr = [];

    for (var key in jsonValue) {

        resultArr.push(
            searchInArray(array, jsonValue[key], searchKey, resultKey)
        );

    }

    result = resultArr.join(" / ");

    return result;
}