import React from 'react';
import TabMenu from "../../components/TabMenu/TabMenu";
import {profileMenu} from "../../menu/ProfileMenu";
import ProfilePasswordForm from "../../components/Pages/Profile/ProfilePasswordForm";

const ProfilePasswordPage = () => {
    return (
        <div>
            <div id="page_content_title">Мой профиль</div>

            <TabMenu menu={profileMenu}/>

            <ProfilePasswordForm />

        </div>
    );
};

export default ProfilePasswordPage;