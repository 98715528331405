import React from 'react';

const NotFoundPage = () => {
    return (
        <div>
            <div id="page_content_title">Страница не найдена</div>

            <p>
                Запрошенная страница не найдена.<br />
                Выберите необходимый раздел в левом меню.
            </p>

        </div>
    );
};

export default NotFoundPage;