import React from 'react';
import {getPurchasesFieldValue} from "../../../utils/GetPurchasesFieldValue";

const PurchasesViewTr = ({field, value, references, item}) => {

    //Получение значения
    const fieldValue = getPurchasesFieldValue(field, value, references, item);

    //Вывод
    return (
        <tr>
            <td>{field.title}</td>
            <td>{fieldValue}</td>
        </tr>
    );
};

export default PurchasesViewTr;