import React, {useEffect, useState, useContext} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import {inputChange} from "../../../utils/InputChange";
import RulesService from "../../../services/RulesService";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {errors422} from "../../../utils/Errors422";
import {tabsFunc} from "../../../utils/tabsFunc";
import {checkboxChecked3d, isChecked} from "../../../utils/CheckboxFun";
import {mainMenu} from "../../../menu/MainMenu";
import {AuthContext} from "../../../context/AuthContext";
import {purchasesFields} from "../../../configs/PurchasesFields";


const RulesEditForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);

    //
    let FormTitle = '';

    if(tekItemId === 0)
    {
        FormTitle = 'Добавление группы';
    }
    else
    {
        FormTitle = 'Редактирование группы';
    }


    //
    const nullItem = {
        name: '',
        note: '',
        rules: {},
        purchases_fields: {},
    };


    //
    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0) {
                setItem(nullItem);
            } else {
                const getItem = async (id) => {
                    const response = await RulesService.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }
        }

    }, [modal, modal]);



    //Сохранение данных
    const handleSubmit = async () => {

        //
        let errArr = [];


        //
        if(item.name === '')
        {
            errArr.push('Поле «Наименование группы» не заполнено');
        }


        //
        if(errArr.length === 0)
        {
            try {
                //
                let response;

                if(tekItemId === 0)
                {
                    response = await RulesService.create(item);
                }
                else
                {
                    response = await RulesService.updateById(tekItemId, item);
                }

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    if(AppAuthStore.user.rule_id === tekItemId)
                    {
                        AppAuthStore.rules = item.rules;
                        AppAuthStore.purchasesFields = item.purchases_fields;
                    }

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения группы.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения группы.');
            }

        }


        //
        setErrors(errArr);
    }


    //
    return (
        <ModalWindow title={FormTitle} active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>


            <div className="horizontal_tabs">
                <ul>
                    <li className="active" onClick={ e => tabsFunc(e) }><span>Общие сведения</span></li>
                    <li className="" onClick={ e => tabsFunc(e) }><span>Общие разрешения</span></li>
                    <li className="" onClick={ e => tabsFunc(e) }><span>Дополнительные разрешения</span></li>
                    <li className="" onClick={ e => tabsFunc(e) }><span>Поля закупок</span></li>
                </ul>
            </div>

            <div className="horizontal_tabs_body_set">

                <div className="horizontal_tabs_body active">


                    <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                        <tbody>
                        <tr>
                            <td>Наименование группы <span className="zv">*</span></td>
                            <td>
                                <input
                                    type="text"
                                    name="name"
                                    value={item.name}
                                    onChange={e => setItem(inputChange(e, item))}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Примечание</td>
                            <td>
                                <textarea
                                    name="note"
                                    value={item.note || ''}
                                    onChange={e => setItem(inputChange(e, item))}
                                ></textarea>
                            </td>
                        </tr>

                        <tr>
                            <td></td>
                            <td>
                                <input
                                    type="submit"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={ () => handleSubmit() }
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>


                </div>



                <div className="horizontal_tabs_body">

                    <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                        <tbody>
                            <tr>
                                <td><b>Раздел</b></td>
                                <td><b>Просмотр</b></td>
                                <td><b>Добавление</b></td>
                                <td><b>Редактирование</b></td>
                                <td><b>Удаление</b></td>
                            </tr>
                            {
                                mainMenu.filter(
                                    (menu) => menu.path !== ''
                                ).map(
                                    (menu, index) =>
                                        <tr key={index}>
                                            <td>{menu.title}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={menu.path}
                                                    className="rule_view"
                                                    checked={ isChecked(item.rules, [menu.path, 'view']) }
                                                    onChange={e => setItem(checkboxChecked3d(e, item, 'rules', 'view'))}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={menu.path}
                                                    className="rule_add"
                                                    checked={ isChecked(item.rules, [menu.path, 'add']) }
                                                    onChange={e => setItem(checkboxChecked3d(e, item, 'rules', 'add'))}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={menu.path}
                                                    className="rule_edit"
                                                    checked={ isChecked(item.rules, [menu.path, 'edit']) }
                                                    onChange={e => setItem(checkboxChecked3d(e, item, 'rules', 'edit'))}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={menu.path}
                                                    className="rule_del"
                                                    checked={ isChecked(item.rules, [menu.path, 'del']) }
                                                    onChange={e => setItem(checkboxChecked3d(e, item, 'rules', 'del'))}
                                                />
                                            </td>
                                        </tr>
                                )
                            }
                            <tr>
                                <td></td>
                                <td colSpan="4">
                                    <input
                                        type="submit"
                                        name="submit"
                                        value="Сохранить"
                                        onClick={ () => handleSubmit() }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>


                <div className="horizontal_tabs_body">

                    <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                        <tbody>

                        <tr>
                            <td colSpan="2"><b>Закупки</b></td>
                        </tr>

                        <tr>
                            <td>Назначение исполнителей</td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="purchases"
                                    className="rule_tuser"
                                    checked={ isChecked(item.rules, ['purchases', 'tuser']) }
                                    onChange={e => setItem(checkboxChecked3d(e, item, 'rules', 'tuser'))}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td></td>
                            <td>
                                <input
                                    type="submit"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={() => handleSubmit()}
                                />
                            </td>
                        </tr>

                        </tbody>
                    </table>

                </div>


                <div className="horizontal_tabs_body">

                    <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                        <tbody>
                            <tr>
                                <td><b>Поле</b></td>
                                <td><b>Просмотр - список</b></td>
                                <td><b>Просмотр - карточка</b></td>
                                <td><b>Редактирование</b></td>
                            </tr>
                            {
                                purchasesFields.map(
                                    (field, index) =>
                                        <tr key={index}>
                                            <td>{field.title}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={field.name}
                                                    className="rule_view"
                                                    checked={ isChecked(item.purchases_fields, [field.name, 'view1']) }
                                                    onChange={e => setItem(checkboxChecked3d(e, item, 'purchases_fields', 'view1'))}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={field.name}
                                                    className="rule_view"
                                                    checked={ isChecked(item.purchases_fields, [field.name, 'view2']) }
                                                    onChange={e => setItem(checkboxChecked3d(e, item, 'purchases_fields', 'view2'))}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={field.name}
                                                    className="rule_add"
                                                    checked={ isChecked(item.purchases_fields, [field.name, 'edit']) }
                                                    onChange={e => setItem(checkboxChecked3d(e, item, 'purchases_fields', 'edit'))}
                                                />
                                            </td>
                                        </tr>
                                )
                            }
                            <tr>
                                <td></td>
                                <td colSpan="2">
                                    <input
                                        type="submit"
                                        name="submit"
                                        value="Сохранить"
                                        onClick={ () => handleSubmit() }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>


            </div>

        </ModalWindow>
    );

};

export default RulesEditForm;