import React from 'react';

const ModalWindowEmpty = ({children, active, setActive}) => {

    const rootClasses = ['modal']

    if (active) {
        rootClasses.push('active');
    }

    return (
        <div className={rootClasses.join(' ')} onClick={() => setActive(false)}>
            <div className="modal_content" onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default ModalWindowEmpty;