import React from 'react';

const PurchasesTUserItem = ({item}) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.fio}</td>
            <td style={{textAlign: "center"}}>{item.purchases_count_1}</td>
            <td style={{textAlign: "center"}}>{item.purchases_count_4}</td>
            <td style={{textAlign: "center"}}>{item.purchases_count_5}</td>
        </tr>
    );
};

export default PurchasesTUserItem;