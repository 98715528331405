import React, {useContext, useEffect} from 'react';
import usePageTitle from "./hooks/usePageTitle";
import {AuthContext} from "./context/AuthContext";
import {observer} from "mobx-react-lite";
import Preloader from "./components/Interface/Preloader";
import AppRouter from "./components/AppRouter";

function App() {

    //
    document.title = usePageTitle('Документооборот по закупкам');


    //
    const {AppAuthStore} = useContext(AuthContext);


    //
    useEffect(() => {
        //if (localStorage.getItem('token')) {}
        AppAuthStore.checkAuth();
    }, []);


    //
    if(AppAuthStore.isLoading)
    {
        return (
            <Preloader />
        );
    }


    //
    return (
        <AppRouter />
    );
}

export default observer(App);