import React, {useContext, useState} from 'react';
import {AuthContext} from "../../../context/AuthContext";
import FormErrors from "../../Interface/FormMessage/FormErrors";


const LoginForm = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [errors, setErrors] = useState([]);


    const handleSubmit = async () => {

        let errArr = [];

        //
        if(username === '')
        {
            errArr.push('Поле «Логин» не заполнено');
        }

        //
        if(password === '')
        {
            errArr.push('Поле «Пароль» не заполнено');
        }

        //
        if(errArr.length === 0)
        {
            const res = await AppAuthStore.login(username, password);

            if(!res)
            {
                errArr.push('Неверный логин или пароль');
            }
        }

        //
        setErrors(errArr);

    };


    const {AppAuthStore} = useContext(AuthContext);

    return (
        <form>

            <FormErrors errors={errors}/>

            <input
                type="text"
                name="username"
                className="page_auth_field"
                value={username}
                onChange={e => setUsername(e.target.value)}
                placeholder="Логин"
                autoComplete="off"
            />

            <input
                type="password"
                name="login"
                className="page_auth_field"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="Пароль"
                autoComplete="off"
            />

            <input type="button"
               name="button"
               className="page_auth_btn"
               onClick={() => handleSubmit() }
               value="Войти"
            />

        </form>
    );
};

export default LoginForm;