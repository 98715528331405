export const settingsMenu = [
    {
        title: 'Закупки',
        path: 'settings/purchases'
    },
    {
        title: 'Приказы',
        path: 'settings/orders'
    },
    {
        title: 'Жалобы',
        path: 'settings/complaints'
    },
    {
        title: 'Сотрудники',
        path: 'settings/employees'
    },
    /*
    {
        title: 'Почта (SMTP)',
        path: 'settings/smtp'
    },
     */
];