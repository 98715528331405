import React from 'react';


const FormSelect = ({name, options, defaultValue, value, onChange, valueKey, nameKey, defValue, defName, className}) => {

    //
    let tekValueKey = valueKey ? valueKey : 'value';
    let tekNameKey = nameKey ? nameKey : 'name';

    //
    return (
        <select name={name} value={value} onChange={onChange} className={className}>
            <option disabled value="">{defaultValue}</option>

            {
                defValue || defName
                    ?
                    <option key={defValue} value={defValue}>{defName}</option>
                    :
                    <></>
            }

            {
                options.map(
                    option =>
                            <option key={option[tekValueKey]} value={option[tekValueKey]}>{option[tekNameKey]}</option>
                )
            }

        </select>
    );
};

export default FormSelect;