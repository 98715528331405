import React from 'react';
import OrdersItem from "./OrdersItem";

const OrdersList = ({items, isLoading, func, references}) => {

    //
    if (!items.length && ! isLoading) {
        return (
            <tbody>
                <tr>
                    <td colSpan="12">Записи не найдены!</td>
                </tr>
            </tbody>
        )
    }

    //
    return (
        <tbody>
        {
            items.map(
                (item, index) =>
                    <OrdersItem
                        key={item.id}
                        item={item}
                        func={func}
                        references={references}
                    />
            )
        }
        </tbody>
    );
};

export default OrdersList;