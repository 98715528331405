import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";

export default class AuthStore {

    isAuth = false;
    user = {};
    isAccess = true;
    isLoading = false;
    isCheckAuth = false;
    jsonSettings = {};
    rules = {};
    purchases_fields = {};
    executor = 0;


    constructor() {
        makeAutoObservable(this);
    }


    setAuth(bool){
        this.isAuth = bool;
    }


    setUser(user){
        this.user = user;
    }


    setAccess(bool){
        this.isAccess = bool;
    }


    setLoading(bool){
        this.isLoading = bool;
    }


    setCheckAuth(bool){
        this.isCheckAuth = bool;
    }


    setExecutor(int){
        this.executor = int;
    }


    async login(username, password){

        let res = false;

        try {
            const response = await AuthService.login(username, password);
            localStorage.setItem('token', response.data.access_token);
            this.checkAuth();
            res = true;
        }
        catch (e) {
            //console.log(e);
            //e.response.status
        }

        return res;
    }


    async logout(username, password){

        try {
            //const response =
            await AuthService.logout();
            localStorage.removeItem('token');
            this.setUser({});
            this.setAuth(false);
        }
        catch (e) {
            //console.log(e);
        }

    }


    async checkAuth(){

        this.setLoading(true);

        try {
            const response = await AuthService.me();
            this.setUser(response.data);
            this.setAuth(true);

            const jsonSettings = (response.data.settings !== null) ? response.data.settings : {};
            this.getUserSettings(jsonSettings);

            this.rules = (response.data.rules !== null) ? response.data.rules : {};
            this.purchasesFields = (response.data.purchases_fields !== null) ? response.data.purchases_fields : {};

            this.setExecutor(response.data.executor);
        }
        catch (e) {
            //console.log(e);
        }
        finally {
            this.setLoading(false);
            this.setCheckAuth(true);
        }

    }


    checkAccess(){
        this.setAccess(true);
    }


    getUserSettings(jsonSettings){
        this.jsonSettings = jsonSettings;
        this.checkUserSettings('home_page', '/');
        this.checkUserSettings('records_count_purchases', '10');
        this.checkUserSettings('records_count_orders', '10');
        this.checkUserSettings('records_count_complaints', '10');
        this.checkUserSettings('records_count_commissions', '10');
        this.checkUserSettings('records_count_departments', '10');
        this.checkUserSettings('records_count_employees', '10');
        this.checkUserSettings('records_count_rules', '10');
        this.checkUserSettings('records_count_journal', '10');
    }


    checkUserSettings(prop, defValue){

        if(!this.jsonSettings.hasOwnProperty(prop))
        {
            this.jsonSettings[prop] = defValue;
        }

        localStorage.setItem('config__' + prop, this.jsonSettings[prop]);
    }


}