export const mainMenu = [
    {
        title: 'Главная',
        icon: '0xf015',
        path: ''
    },
    {
        title: 'Закупки',
        icon: '0xf0b1',
        path: 'purchases'
    },
    {
        title: 'Приказы',
        icon: '0xf233',
        path: 'orders'
    },
    {
        title: 'Жалобы',
        icon: '0xf0a1',
        path: 'complaints'
    },
    {
        title: 'Комиссия',
        icon: '0xf046',
        path: 'commissions'
    },
    {
        title: 'Отчеты',
        icon: '0xf080',
        path: 'reports'
    },
    {
        title: 'Отделы',
        icon: '0xf0e8',
        path: 'departments'
    },
    {
        title: 'Сотрудники',
        icon: '0xf0c0',
        path: 'employees'
    },
    {
        title: 'Права доступа',
        icon: '0xf13e',
        path: 'rules'
    },
    {
        title: 'Журнал действий',
        icon: '0xf017',
        path: 'journal'
    },
    {
        title: 'Настройки',
        icon: '0xf085',
        path: 'settings'
    },
];