import React from 'react';
import ModalWindowEmpty from "./ModalWindowEmpty";

const ModalWindow = ({title, children, active, setActive}) => {
    return (
        <ModalWindowEmpty active={active} setActive={setActive}>

            <div className="modal_window">
                <div className="modal_window_head">
                    {title}
                    <div className="modal_window_close" onClick={() => setActive(false)}>&#xf00d;</div>
                </div>
                <div className="modal_window_body">
                    {children}
                </div>
            </div>

        </ModalWindowEmpty>
    );
};

export default ModalWindow;