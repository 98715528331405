import React from 'react';
import CommissionsContent from "../../components/Pages/Commissions/CommissionsContent";

const CommissionsPage = () => {
    return (
        <div>
            <div id="page_content_title">Комиссия</div>

            <CommissionsContent />

        </div>
    );
};

export default CommissionsPage;