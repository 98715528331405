import React from 'react';
import OrdersContent from "../../components/Pages/Orders/OrdersContent";

const OrdersPage = () => {
    return (
        <div>
            <div id="page_content_title">Приказы</div>

            <OrdersContent />

        </div>
    );
};

export default OrdersPage;