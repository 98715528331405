import React from 'react';
import {inputChange} from "../../../utils/InputChange";
import FormSelect from "../../Interface/FormSelect";
import {dateFormat} from "../../../utils/DateFormat";

const PurchasesEditTr = ({field, value, references, item, setItem}) => {

    //Параметры поля
    let paramStr = '';
    let param = [];

    switch (field.type) {

        case 'date':
            //
            break;

        case 'ref':
            paramStr = field.definition;
            param = paramStr.split(':');
            break;

        default:
        //
    }

    //Вывод
    return (
        <tr>
            <td>{field.title}</td>
            <td>

                {
                    field.type === ''
                        ?
                        <input
                            type="text"
                            name={field.name}
                            value={item[field.name] || ''}
                            onChange={e => setItem(inputChange(e, item))}
                            autoComplete="off"
                        />
                        :
                        <></>
                }

                {
                    field.type === 'date'
                        ?
                        <input
                            type="date"
                            name={field.name}
                            value={item[field.name] ? dateFormat(item[field.name], 'Y-m-d') : ''}
                            onChange={e => setItem(inputChange(e, item))}
                            autoComplete="off"
                        />
                        :
                        <></>
                }

                {
                    field.type === 'textarea'
                        ?
                        <textarea
                            name={field.name}
                            value={item[field.name] || ''}
                            onChange={e => setItem(inputChange(e, item))}
                            autoComplete="off"
                        ></textarea>
                        :
                        <></>
                }

                {
                    field.type === 'ref'
                        ?
                        <FormSelect
                            name={field.name}
                            options={references[param[0]]}
                            defaultValue="Выберите значение"
                            value={item[field.name] || ''}
                            onChange={e => setItem(inputChange(e, item))}
                            valueKey={param[1]}
                            nameKey={param[2]}
                            defValue="0"
                        />
                        :
                        <></>
                }

            </td>
        </tr>
    );
};

export default PurchasesEditTr;