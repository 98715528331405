import React, {useContext, useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import {AuthContext} from "../../../context/AuthContext";
import {purchasesFields} from "../../../configs/PurchasesFields";
import PurchasesViewTr from "./PurchasesViewTr";
import PurchasesServise from "../../../services/PurchasesServise";

const viewField = (obj, name) => {

    if(name !== '')
    {
        if(obj[name] === undefined)
        {
            return false;
        }
        else if(obj[name]['view2'] === undefined)
        {
            return false;
        }
        else if(obj[name]['view2'] === '0')
        {
            return false;
        }
    }

    return true;
}

const PurchasesViewForm = ({modal, setModal, tekItemId, references}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userPurchasesFields = AppAuthStore.purchasesFields;

    //Поля пользователя
    let userFields = purchasesFields.filter(
        (field) => viewField(userPurchasesFields, field.name)
    );

    //
    const nullItem = {
    };

    //
    const [item, setItem] = useState(nullItem);
    const [isItemLoading, setIsItemLoading] = useState(false);

    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0)
            {
                setItem(nullItem);
            }
            else
            {
                const getItem = async (id) => {
                    const response = await PurchasesServise.getById(id);
                    setItem(response.data.data);
                }

                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Вывод
    return (
        <ModalWindow title="Просмотр закупки" active={modal} setActive={setModal}>

            <table cellPadding="0" cellSpacing="0" border="0" className="view_table">
                <tbody>
                    {
                        userFields.map(
                            (tr, index) =>
                                <PurchasesViewTr
                                    key={tr.name}
                                    field={tr}
                                    value={item[tr.name]}
                                    item={item}
                                    references={references}
                                />
                        )
                    }
                </tbody>
            </table>

        </ModalWindow>
    );
};

export default PurchasesViewForm;