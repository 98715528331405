import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import SettingsService from "../../../services/SettingsService";
import {errors422} from "../../../utils/Errors422";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {inputChange} from "../../../utils/InputChange";
import MultiField from "../../Interface/MultiField";
import {RuleEdit} from "../../Interface/Rule/RuleAction";
import {scrollToTop} from "../../../utils/ScrollToTop";

const SettingsEmployeesForm = () => {

    //Получение настроек
    const [settings, setSettings] = useState({employees_positions: ''});

    const [fetchSettings, isLoading, error] = useFetching(
        async () => {
            const response = await SettingsService.getSettings('employees_positions');
            setSettings(response.data.data);
        }
    );

    useEffect(() => {
        fetchSettings();
    }, []);


    //Сохранение настроек
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSubmit = async () => {

        //
        scrollToTop();

        //
        let errArr = [];

        //
        if(errArr.length === 0) {

            //
            try {
                const response = await SettingsService.setSettings(settings);

                if(response.data.status === 'ok')
                {
                    setSuccess(true);
                    setTimeout(() => {  setSuccess(false) }, 2500);
                }
                else
                {
                    errArr.push('Ошибка сохранения настроек.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения настроек.');
            }

        }

        //
        setErrors(errArr);

    }


    //
    return (
        <div>

            <FormSuccess ok={success} text="Настройки успешно сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                    <tr>
                        <td colSpan="2"><b>Справочники</b></td>
                    </tr>

                    <tr>
                        <td>
                            Должности сотрудников
                        </td>
                        <td>
                            <MultiField
                                name="employees_positions"
                                value={settings.employees_positions}
                                onChange={ (e) => setSettings(inputChange(e, settings)) }
                                heads="ID|Значение"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td>
                            <RuleEdit>
                                <input
                                    type="button"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={() => handleSubmit() }
                                    className="rule_edit"
                                />
                            </RuleEdit>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </form>

        </div>
    );
};

export default SettingsEmployeesForm;