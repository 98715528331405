export const purchasesFields = [
    {
        title: 'ID',
        name: 'id',
        type: '',
        definition: '',
        etap: 'null',
    },
    {
        title: 'Дата поступления',
        name: 'receipt_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'add,edit',
    },
    {
        title: 'Регистрационный номер',
        name: 'reg_num',
        type: '',
        definition: '',
        etap: 'add,edit',
    },
    {
        title: 'Исходящий номер письма',
        name: 'outgoing_num',
        type: '',
        definition: '',
        etap: 'add,edit',
    },
    {
        title: 'Номер закупки в РИС',
        name: 'ris_num',
        type: '',
        definition: '',
        etap: 'add,edit',
    },
    {
        title: 'Вид заказчика',
        name: 'zak_type',
        type: 'ref',
        definition: 'zakTypeList:0:1',
        etap: 'add,edit',
    },
    {
        title: 'Заказчик (Наименование)',
        name: 'zak_name',
        type: 'textarea',
        definition: '',
        etap: 'add,edit',
    },
    {
        title: 'Предмет закупки',
        name: 'predmet',
        type: 'textarea',
        definition: '',
        etap: 'add,edit',
    },
    {
        title: 'Способ закупки ',
        name: 'method',
        type: 'ref',
        definition: 'methodList:0:1',
        etap: 'edit',
    },
    {
        title: 'Совместная закупка',
        name: 'joint',
        type: 'ref',
        definition: 'NoYesList:0:1',
        etap: 'edit',
    },
    {
        title: 'НМЦК',
        name: 'nmck',
        type: '',
        definition: '',
        etap: 'edit',
    },
    {
        title: 'Исполнитель',
        name: 'contractor',
        type: 'ref',
        definition: 'contractorList:id:fio',
        etap: 'edit,tuser',
    },
    {
        title: 'Реестровый номер ЕИСа',
        name: 'eis_num',
        type: '',
        definition: '',
        etap: 'edit,cwork_s4',
    },
    {
        title: 'Дата публикации в ЕИС',
        name: 'eis_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit,cwork_s4',
    },
    {
        title: 'ЭА - Дата окончания подачи заявок',
        name: 'ea_end_zay_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit,cwork_s1_t1,cwork_s_5_t1',
    },
    {
        title: 'ЭА - Дата подведения итогов',
        name: 'ea_itogs_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit,cwork_s1_t1,cwork_s_5_t1',
    },
    {
        title: 'ЭК - Дата окончания подачи заявок',
        name: 'ek_end_zay_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit,cwork_s1_t2,cwork_s_5_t2',
    },
    {
        title: 'ЭК - Дата рассмотрения и оценки 1-х частей',
        name: 'ek_part1_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit,cwork_s1_t2,cwork_s_5_t2',
    },
    {
        title: 'ЭК - Дата рассмотрения и оценки 2-х частей',
        name: 'ek_part2_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit,cwork_s1_t2,cwork_s_5_t2',
    },
    {
        title: 'ЭК - Дата подведения итогов',
        name: 'ek_itogs_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit,cwork_s1_t2,cwork_s_5_t2',
    },
    {
        title: 'Состав комиссии',
        name: 'commission_id',
        type: 'ref',
        definition: 'commissionsList:id:num',
        etap: 'edit,cwork_s1_t1,cwork_s1_t2',
    },
    {
        title: 'Приказ на размещение',
        name: 'placement_order_id',
        type: 'relation',
        definition: 'orders:type_id:1:№ num от reg_date',
        etap: 'edit',
    },
    {
        title: 'Закупка на контроле',
        name: 'control',
        type: 'ref',
        definition: 'NoYesList:0:1',
        etap: 'edit,tuser',
    },
    {
        title: 'Срочность',
        name: 'urgency',
        type: 'ref',
        definition: 'urgencyList:0:1',
        etap: 'edit,tuser',
    },
    {
        title: 'Установить короткие сроки',
        name: 'short_deadlines',
        type: 'ref',
        definition: 'NoYesList:0:1',
        etap: 'edit,tuser',
    },
    {
        title: 'Закупка после доработки',
        name: 'after_completion',
        type: 'ref',
        definition: 'NoYesList:0:1',
        etap: 'edit',
    },
    {
        title: 'Кол-во участников закупки',
        name: 'parts_count',
        type: '',
        definition: '',
        etap: 'edit,cwork_s7',
    },
    {
        title: 'Кол-во допущенных участников',
        name: 'adm_parts_count',
        type: '',
        definition: '',
        etap: 'edit,cwork_s7',
    },
    {
        title: 'Победитель - ИНН',
        name: 'winner_inn',
        type: '',
        definition: '',
        etap: 'edit,cwork_s7',
    },
    {
        title: 'Победитель - Наименование',
        name: 'winner_name',
        type: '',
        definition: '',
        etap: 'edit,cwork_s7',
    },
    {
        title: 'Победитель - Регион',
        name: 'winner_region',
        type: '',
        definition: '',
        etap: 'edit,cwork_s7',
    },
    {
        title: 'Цена победителя',
        name: 'winner_price',
        type: '',
        definition: '',
        etap: 'edit,cwork_s7',
    },
    {
        title: 'Процент экономии по торгам',
        name: 'percent_economy',
        type: '',
        definition: '',
        etap: 'edit',
    },
    {
        title: 'Статус закупки',
        name: 'status',
        type: 'ref',
        definition: 'statusList:0:1',
        etap: 'edit,tuser,cwork_s5,cwork_s6,cwork_s7,cwork_s8,cwork_s_5_t1,cwork_s_5_t2',
    },
    {
        title: 'Дата изменения статуса',
        name: 'status_date',
        type: 'date',
        definition: 'd.m.Y',
        etap: 'edit',
    },
    {
        title: 'Причина отмены',
        name: 'cancel_reason',
        type: 'ref',
        definition: 'cancelReasonList:0:1',
        etap: 'edit,cwork_s6',
    },
    {
        title: 'Причина: не состоялась',
        name: 'nottake_reason',
        type: 'ref',
        definition: 'nottakeReasonList:0:1',
        etap: 'edit,cwork_s8',
    },
    {
        title: 'Причина изменения',
        name: 'changes_reason',
        type: 'ref',
        definition: 'changesReasonList:0:1',
        etap: 'edit,cwork_s5,cwork_s_5_t1,cwork_s_5_t2',
    },
    {
        title: 'Примечание',
        name: 'note',
        type: 'textarea',
        definition: '',
        etap: 'add,edit,tuser',
    },
];















