export const tabsFunc = (e) => {

    //
    e.preventDefault();


    //
    const thisElem = e.target.parentNode;


    //
    let t_i =  getNodeIndex(thisElem);


    //
    const tabHeadElems = thisElem.parentNode.querySelectorAll('li');
    for (let i = 0; i < tabHeadElems.length; i++) {
        tabHeadElems[i].classList.remove('active');
    }

    tabHeadElems[t_i].classList.add('active');


    //
    const tabBodyElems = thisElem.parentNode.parentNode.parentNode.querySelectorAll('.horizontal_tabs_body');
    for (let i = 0; i < tabBodyElems.length; i++) {
        tabBodyElems[i].classList.remove('active');
    }

    tabBodyElems[t_i].classList.add('active');

}

const getNodeIndex = (elem) => {
    var c = elem.parentNode.children,
        i = c.length;

    while(i--)
        if( c[i] == elem )
            return i
}