import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import DepartmentsService from "../../../services/DepartmentsService";
import Preloader from "../../Interface/Preloader";
import DepartmentsList from "./DepartmentsList";
import Pagination from "../../Interface/Pagination";
import {getPageCount} from "../../../utils/Pages";
import DepartmentsDelForm from "./DepartmentsDelForm";
import DepartmentsEditForm from "./DepartmentsEditForm";
import RulesService from "../../../services/RulesService";
import {RuleAdd} from "../../Interface/Rule/RuleAction";


const DepartmentsContent = () => {

    //
    const [rolesList, setRolesList] = useState([])

    const [items, setItems] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(localStorage.getItem('config__records_count_departments'));

    const [totalElems, setTotalElems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [tekItemId, setTekItemId] = useState(0);
    const [updTrigger, setUpdTrigger] = useState(0);

    const [modalEdit, setModalEdit] = useState(false);
    const [modalDel, setModalDel] = useState(false);


    //
    const [fetchAdds, isAddsLoading, addsError] = useFetching(
        async () => {
            const response = await RulesService.getAllHelper();
            setRolesList(response.data.data.items);
        }
    );

    useEffect(() => {
        fetchAdds();
    }, []);


    //
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async (page, limit) => {
            const response = await DepartmentsService.getAll(page, limit);
            setItems(response.data.data.items);
            const totalCount = response.data.data.total;
            setTotalElems(totalCount);
            setTotalPages(getPageCount(totalCount, limit));
        }
    );


    //
    useEffect(() => {
        fetchItems(page, limit);
    }, [page, limit, updTrigger])


    //
    const changePage = (page) => {
        setPage(page)
    }


    //Добавление записи
    const addItemInit = (e) => {
        e.preventDefault();
        setTekItemId(0);
        setModalEdit(true);
    }


    //Редактирование записи
    const editItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalEdit(true);
    }


    //Удаление записи
    const delItemInit = (e, id) => {
        e.preventDefault();
        setTekItemId(id);
        setModalDel(true);
    }


    //Передача функций
    const func = {
        edit: editItemInit,
        del: delItemInit
    }


    //
    if(isAddsLoading)
    {
        return (
            <Preloader />
        );
    }


    //
    return (
        <div>

            <div className="btn_right_top_panel">

                <RuleAdd>
                    <a href="#"
                       className="btn btn_with_icon btn_modal rule_add"
                       onClick={ e => addItemInit(e) }
                    >
                        <span>&#xf067;</span> Добавить отдел
                    </a>
                </RuleAdd>

            </div>

            {
                itemsError
                    ?
                    <div>Произошла ошибка ${itemsError}</div>
                    :
                    <></>
            }

            <DepartmentsList
                items={items}
                isLoading={isItemsLoading}
                func={func}
                rolesList={rolesList}
            />

            <Pagination
                page={page}
                changePage={changePage}
                totalElems={totalElems}
                totalPages={totalPages}
                itemsLimit={limit}
                itemsCount={items.length}
            />

            {
                isItemsLoading
                    ?
                    <Preloader />
                    :
                    <></>
            }

            <DepartmentsDelForm
                modal={modalDel}
                setModal={setModalDel}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
            />

            <DepartmentsEditForm
                modal={modalEdit}
                setModal={setModalEdit}
                tekItemId={tekItemId}
                setUpdTrigger={setUpdTrigger}
                rolesList={rolesList}
            />

        </div>
    );
};

export default DepartmentsContent;