import React, {useContext, useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import PurchasesServise from "../../../services/PurchasesServise";
import PurchasesEditTr from "./PurchasesEditTr";
import {AuthContext} from "../../../context/AuthContext";
import {purchasesFields} from "../../../configs/PurchasesFields";
import {checkField} from "../../../utils/CheckField";
import {checkFieldEtap} from "../../../utils/СheckFieldEtap";
import {errors422} from "../../../utils/Errors422";
import {tabsFunc} from "../../../utils/tabsFunc";
import PurchasesTUserList from "./PurchasesTUserList";


const PurchasesTUserForm = ({modal, setModal, tekItemId, setUpdTrigger, references}) => {

    //Получение пользователя
    const {AppAuthStore} = useContext(AuthContext);
    const userPurchasesFields = AppAuthStore.purchasesFields;

    //Поля пользователя
    let userFields = purchasesFields.filter(
        (field) => checkField(userPurchasesFields, field.name, 'edit')
    );

    //Этап полей
    let FieldEtap = 'tuser';

    let references_mod;

    //Для формы
    const [item, setItem] = useState({});

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    //Получение данных
    useEffect(() => {

        //
        if(modal)
        {
            //Список актуальных полей
            let getFieldsList = [];

            userFields.filter(
                (field) => checkFieldEtap(field.etap, FieldEtap)
            ).forEach((field) => {
                getFieldsList.push(field.name);
            })

            //Запрос
            const getItem = async (id) => {
                const response = await PurchasesServise.getByIdWithFields(id, getFieldsList);
                setItem(response.data.data);
            }
            getItem(tekItemId);
        }

        //
        //Доступные статусы
        references_mod = references;
        references_mod.statusList = references_mod.statusList.filter(function(status){
            return status[0] === '1' || status[0] === '2' || status[0] === '3';
        });

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async (e) => {

        //
        e.preventDefault();

        //
        let errArr = [];

        //
        if(item.contractor === 0)
        {
            errArr.push('Поле «Исполнитель» не заполнено');
        }

        //
        if(errArr.length === 0)
        {
            try {
                //
                const response = await PurchasesServise.updateById(tekItemId, item);

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения закупки.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения закупки.');
            }

        }

        //
        setErrors(errArr);
    }

    //Вывод формы
    return (
        <ModalWindow title="Назначение исполнителя" active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>


            <div className="horizontal_tabs">
                <ul>
                    <li className="active" onClick={ e => tabsFunc(e) }><span>Назначение исполнителя</span></li>
                    <li className="" onClick={ e => tabsFunc(e) }><span>Занятость исполнителей</span></li>
                </ul>
            </div>


            <div className="horizontal_tabs_body_set">


                <div className="horizontal_tabs_body active">

                    <form>

                        <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                            <tbody>

                            {
                                userFields.filter(
                                    (field) => checkFieldEtap(field.etap, FieldEtap)
                                ).map(
                                    (tr, index) =>
                                        <PurchasesEditTr
                                            key={tr.name}
                                            field={tr}
                                            value={item[tr.name]}
                                            references={references}
                                            item={item}
                                            setItem={setItem}
                                        />
                                )
                            }

                            <tr>
                                <td></td>
                                <td>
                                    <input
                                        type="submit"
                                        name="submit"
                                        value="Сохранить"
                                        onClick={ e => handleSubmit(e) }
                                    />
                                </td>
                            </tr>

                            </tbody>
                        </table>

                    </form>

                </div>


                <div className="horizontal_tabs_body">

                    <PurchasesTUserList
                        modal={modal}
                    />

                </div>


            </div>

        </ModalWindow>
    );
};

export default PurchasesTUserForm;