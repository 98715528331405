import React from 'react';

const NotificationsWidget = () => {
    return (
        <div className="data_elem_item data_elem_item_col2">
            <div className="block_panel">
                <div className="block_panel_head">
                    <span>&#xf0a2;</span>Уведомления
                </div>
                <div className="block_panel_body">
                    <div className="data_elem_item_desc">
                        Уведомлений нет
                        <br />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NotificationsWidget;