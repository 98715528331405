import React from 'react';
import NotificationsWidget from "../../components/Pages/Home/NotificationsWidget/NotificationsWidget";
import EventsWidget from "../../components/Pages/Home/EventsWidget/EventsWidget";
import ActionsWidget from "../../components/Pages/Home/ActionsWidget/ActionsWidget";


const HomePage = () => {
    return (
        <div>
            <div id="page_content_title">Главная</div>

            <div id="data_elem_list">

                <NotificationsWidget />

                <EventsWidget />

                <ActionsWidget />

            </div>

        </div>
    );
};

export default HomePage;