import $api from "../http";

export default class OrdersServise {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}) {
        return await $api.get('/orders/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
            }
        })
    }

    static async create(data) {
        return await $api.post('/orders/create', data);
    }

    static async getById(id) {
        return await $api.get('/orders/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/orders/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/orders/' + id + '/delete');
    }

    static async getLast(torg = 0) {
        return await $api.get('/orders/last', {
            params: {
                torg: torg,
                helper: 1,
            }
        });
    }

}