import React from 'react';
import {getPurchasesFieldValue} from "../../../utils/GetPurchasesFieldValue";

const PurchasesTd = ({field, value, references, item}) => {

    //Получение значения
    const fieldValue = getPurchasesFieldValue(field, value, references, item);

    //Выод
    return (
        <td>
            {fieldValue}
        </td>
    );
};

export default PurchasesTd;