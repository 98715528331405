import React, {useEffect, useState} from 'react';
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";
import FormSuccess from "../../Interface/FormMessage/FormSuccess";
import FormErrors from "../../Interface/FormMessage/FormErrors";
import {inputChange} from "../../../utils/InputChange";
import ComplaintsServise from "../../../services/ComplaintsServise";
import {errors422} from "../../../utils/Errors422";
import FormSelect from "../../Interface/FormSelect";
import {dateFormat} from "../../../utils/DateFormat";
import {checkboxChecked1d, isChecked1d} from "../../../utils/CheckboxFun";
import PurchasesServise from "../../../services/PurchasesServise";

const ComplaintsEditForm = ({modal, setModal, tekItemId, setUpdTrigger, references, tekPurchaseId}) => {

    //
    let FormTitle = '';
    let nullItem = {
        purchase:{
            reg_num:''
        }
    };

    if(tekItemId === 0)
    {
        //
        FormTitle = 'Формирование жалобы';

        //
        nullItem['purchase_id'] = tekPurchaseId;
    }
    else
    {
        //
        FormTitle = 'Редактирование жалобы'
    }


    //
    const [item, setItem] = useState(nullItem);

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);


    //Получение данных
    useEffect(() => {

        if(modal) {
            if (tekItemId === 0) {
                const getPurchaseRegNum = async (id) => {
                    const response = await PurchasesServise.getById(id);
                    nullItem.purchase.reg_num = response.data.data.reg_num !== undefined ? response.data.data.reg_num : '';
                    setItem(nullItem);
                }
                getPurchaseRegNum(tekPurchaseId);
            }
            else
            {
                const getItem = async (id) => {
                    const response = await ComplaintsServise.getById(id);
                    setItem(response.data.data);
                }
                getItem(tekItemId);
            }
        }

    }, [modal, modal]);


    //Сохранение данных
    const handleSubmit = async (e) => {

        //
        e.preventDefault();

        //
        let errArr = [];

        //
        if(item.purchase.reg_num === '')
        {
            errArr.push('Поле «Регистрационный номер закупки» не заполнено');
        }

        //
        if(errArr.length === 0)
        {
            try {
                //
                let response;

                if(tekItemId === 0)
                {
                    response = await ComplaintsServise.create(item);
                }
                else
                {
                    response = await ComplaintsServise.updateById(tekItemId, item);
                }

                if(response.data.status === 'ok')
                {
                    //
                    setSuccess(true);
                    setTimeout(
                        () => {
                            setSuccess(false);
                            setModal(false);
                        },
                        1500
                    );

                    //
                    setUpdTrigger( Date.now() );
                }
                else
                {
                    errArr.push('Ошибка сохранения жалобы.');
                }
            }
            catch (e) {
                errors422(e, errArr, 'Ошибка сохранения жалобы.');
            }

        }

        //
        setErrors(errArr);
    }


    //
    return (

        <ModalWindow title={FormTitle} active={modal} setActive={setModal}>

            <FormSuccess ok={success} text="Данные сохранены." fading="true"/>

            <FormErrors errors={errors}/>

            <form>

                <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                    <tbody>

                        <tr>
                            <td>Регистрационный номер закупки <span className="zv">*</span></td>
                            <td>
                                <input
                                    type="text"
                                    name="purchase_reg_num"
                                    value={item.purchase.reg_num || ''}
                                    readOnly={true}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Тип рассмотрения</td>
                            <td>
                                <FormSelect
                                    name="review_type"
                                    options={references.reviewTypeList}
                                    defaultValue="Выберите значение"
                                    value={item.review_type}
                                    onChange={ e => setItem(inputChange(e, item)) }
                                    valueKey="0"
                                    nameKey="1"
                                    defValue="0"
                                    defName=""
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Документация разработана ГКУ?</td>
                            <td>
                                <FormSelect
                                    name="gku_doks"
                                    options={references.NoYesList}
                                    defaultValue="Выберите значение"
                                    value={item.gku_doks}
                                    onChange={ e => setItem(inputChange(e, item)) }
                                    valueKey="0"
                                    nameKey="1"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Категория закупки</td>
                            <td>
                                <FormSelect
                                    name="category"
                                    options={references.categoryList}
                                    defaultValue="Выберите значение"
                                    value={item.category}
                                    onChange={ e => setItem(inputChange(e, item)) }
                                    valueKey="0"
                                    nameKey="1"
                                    defValue="0"
                                    defName=""
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Наименование Жалобщика</td>
                            <td>
                                <textarea
                                    name="complainer_name"
                                    value={item.complainer_name || ''}
                                    onChange={e => setItem(inputChange(e, item))}
                                ></textarea>
                            </td>
                        </tr>

                        <tr>
                            <td>Регион Жалобщика</td>
                            <td>
                                <FormSelect
                                    name="complainer_region"
                                    options={references.regionList}
                                    defaultValue="Выберите значение"
                                    value={item.complainer_region}
                                    onChange={ e => setItem(inputChange(e, item)) }
                                    valueKey="0"
                                    nameKey="1"
                                    defValue="0"
                                    defName=""
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Обжалуемые действия</td>
                            <td>
                                {
                                    references.actionsList.map(
                                        (action, index) =>
                                            <div className="field_wrap_wrap" key={index}>
                                                <input
                                                    type="checkbox"
                                                    name="actions"
                                                    checked={ isChecked1d(action[0], item.actions) }
                                                    onChange={e => setItem(checkboxChecked1d(e, item))}
                                                    value={action[0]}
                                                />
                                                {action[1]}
                                            </div>
                                    )
                                }
                            </td>
                        </tr>


                        {
                            tekItemId !== 0
                                ?
                                <>
                                    <tr>
                                        <td>Дата рассмотрения жалобы</td>
                                        <td>
                                            <input
                                                type="date"
                                                name="review_date"
                                                value={item.review_date ? dateFormat(item.review_date, 'Y-m-d') : ''}
                                                onChange={e => setItem(inputChange(e, item))}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Оценка участия заказчика<br />в рассмотрении жалобы</td>
                                        <td>
                                            <FormSelect
                                                name="evaluation"
                                                options={references.evaluationList}
                                                defaultValue="Выберите значение"
                                                value={item.evaluation}
                                                onChange={ e => setItem(inputChange(e, item)) }
                                                valueKey="0"
                                                nameKey="1"
                                                defValue="0"
                                                defName=""
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Результат рассмотрения</td>
                                        <td>
                                            <FormSelect
                                                name="result"
                                                options={references.resultList}
                                                defaultValue="Выберите значение"
                                                value={item.result}
                                                onChange={ e => setItem(inputChange(e, item)) }
                                                valueKey="0"
                                                nameKey="1"
                                                defValue="0"
                                                defName=""
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Предписание УФАС</td>
                                        <td>
                                            {
                                                references.prescriptionList.map(
                                                    (prescription, index) =>
                                                        <div className="field_wrap_wrap" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                name="prescription"
                                                                checked={ isChecked1d(prescription[0], item.prescription) }
                                                                onChange={e => setItem(checkboxChecked1d(e, item))}
                                                                value={prescription[0]}
                                                            />
                                                            {prescription[1]}
                                                        </div>
                                                )
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Доп.информация</td>
                                        <td>
                                            <textarea
                                                name="additional"
                                                value={item.additional || ''}
                                                onChange={e => setItem(inputChange(e, item))}
                                            ></textarea>
                                        </td>
                                    </tr>
                                </>
                                :
                                <></>
                        }

                        <tr>
                            <td></td>
                            <td>
                                <input
                                    type="submit"
                                    name="submit"
                                    value="Сохранить"
                                    onClick={ e => handleSubmit(e) }
                                />
                            </td>
                        </tr>

                    </tbody>
                </table>

            </form>

        </ModalWindow>

    );
};

export default ComplaintsEditForm;