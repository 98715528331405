export const checkField = (obj, name, rule) => {

    if(name !== '')
    {
        if(obj[name] === undefined)
        {
            return false;
        }
        else if(obj[name][rule] === undefined)
        {
            return false;
        }
        else if(obj[name][rule] === '0')
        {
            return false;
        }
        else if(obj[name][rule] === 0)
        {
            return false;
        }
    }

    return true;
}