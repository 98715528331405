import React from 'react';
import PurchasesContent from "../../components/Pages/Purchases/PurchasesContent";

const PurchasesPage = () => {

    return (
        <div>
            <div id="page_content_title">Закупки</div>

            <PurchasesContent />

        </div>
    );
};

export default PurchasesPage;