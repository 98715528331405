import React, {useEffect, useState} from 'react';
import {useFetching} from "../../../hooks/useFetching";
import EmployeesService from "../../../services/EmployeesService";
import PurchasesTUserItem from "./PurchasesTUserItem";


const PurchasesTUserList = ({modal}) => {

    //Список элементов
    const [items, setItems] = useState([]);


    //
    const [fetchItems, isItemsLoading, itemsError] = useFetching(
        async () => {
            const response = await EmployeesService.getAllExecutionsHelper(1, 100, 'id', 'asc', {executor: {v:'1', t:'='}}, [1, 4, 5]);
            setItems(response.data.data.items);
        }
    );


    //Получение данных
    useEffect(() => {

        if(modal) {
            fetchItems();
        }

    }, [modal, modal]);



    //Вывод
    return (
        <div className="data_table_div">

            <table cellPadding="0" cellSpacing="0" border="0">
                <thead>
                    <tr>
                        <td rowSpan="2" style={{verticalAlign: "middle"}}>ID</td>
                        <td rowSpan="2" style={{verticalAlign: "middle"}}>ФИО пользователя</td>
                        <td colSpan="3" style={{textAlign: "center"}}>Количество закупок в работе</td>
                    </tr>
                    <tr>
                        <td>В работе</td>
                        <td>На размещении</td>
                        <td>Опубликована</td>
                    </tr>
                </thead>

                <tbody>
                    {
                        items.map(
                            (item, index) =>
                                <PurchasesTUserItem
                                    key={item.id}
                                    item={item}
                                />
                        )
                    }
                </tbody>
            </table>

        </div>
    );
};

export default PurchasesTUserList;