export const errors422 = (e, errArr, otherErrorsText) => {

    if(e.response.status === 422)
    {
        let errors422 = e.response.data.errors

        for (var key in errors422) {
            errArr.push(errors422[key]);
        }
    }
    else
    {
        errArr.push(otherErrorsText);
    }

    return errArr;
}