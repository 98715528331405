import $api from "../http";

export default class JournalsService {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc', filters = {}) {
        return await $api.get('/journal/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                filters: filters,
            }
        })
    }

    //
    static async getAllHelper(page = 1, limit = 100, sort = 'id', direction = 'asc') {
        return await $api.get('/journal/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                helper: 1,
            }
        })
    }

}