import $api from "../http";

export default class DepartmentsService {

    static async getAll(page = 1, limit = 100, sort = 'id', direction = 'asc') {
        return await $api.get('/departments/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
            }
        })
    }

    static async create(data) {
        return await $api.post('/departments/create', data);
    }

    static async getById(id) {
        return await $api.get('/departments/' + id + '/read');
    }

    static async updateById(id, data) {
        return await $api.post('/departments/' + id + '/update', data);
    }

    static async deleteById(id) {
        return await $api.delete('/departments/' + id + '/delete');
    }

    //
    static async getAllHelper(page = 1, limit = 100, sort = 'id', direction = 'asc') {
        return await $api.get('/departments/list', {
            params: {
                page: page,
                limit: limit,
                sort: sort,
                direction: direction,
                helper: 1,
            }
        })
    }
}