import React from 'react';
import OrdersServise from "../../../services/OrdersServise";
import ModalWindow from "../../Interface/ModalWindow/ModalWindow";

const OrdersDelForm = ({modal, setModal, tekItemId, setUpdTrigger}) => {

    //
    const delItemRun = () => {
        setModal(false);
        OrdersServise.deleteById(tekItemId);
        setUpdTrigger( Date.now() );
    }

    //
    return (
        <ModalWindow title="Удаление приказа" active={modal} setActive={setModal}>

            <table cellPadding="0" cellSpacing="0" border="0" className="edit_table">
                <tbody>
                <tr align="center">
                    <td>Вы действительно хотите удалить приказ?</td>
                </tr>
                <tr>
                    <td align="center">
                        <input
                            type="submit"
                            name="submit"
                            value="Подтверждаю"
                            onClick={ () => delItemRun() }
                        />
                    </td>
                </tr>
                </tbody>
            </table>

        </ModalWindow>
    );
};

export default OrdersDelForm;