import React, {useState} from 'react';
import UploadService from "../../services/UploadService";
import Preloader from "./Preloader";

const FormInputFile = ({name, value, onChange, accept, server, storage, dir}) => {

    //
    const [isLoading, setIsLoading] = useState(false);

    //
    const valueArr = value.split('|');
    const fileUrl = server + '/' + storage + '/' + dir + '/' + valueArr[1];

    //Обновление поля
    const field_update = (fieldName, fieldValue) => {

        //
        let inputElem = document.querySelector('input[name="' + fieldName + '"]');
        inputElem.value = value;

        //
        let e = {
            target: {
                name: fieldName,
                value: fieldValue
            }
        };

        onChange(e);
    }


    //Загрузка файла
    const addFile = async (event, fieldName) => {
        event.preventDefault();

        //Запрос на загрузку файла
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('dir', dir);
        formData.append('status', 0);

        setIsLoading(true);

        try {
            const response = await UploadService.uploadFile(formData);

            if(response.data.status === 'ok')
            {
                field_update(fieldName, makeFieldValue(response.data.data));
                formsFilesStorage(response.data.data.id);
            }
        }
        catch (e) {
            event.target.value = '';
        }
        finally {
            setIsLoading(false);
        }

    }


    //Удаление файла
    const delFile = async (e, fieldName, delId) => {
        e.preventDefault();

        //
        try {
            const response = await UploadService.deleteFile(delId);

            if(response.data.status === 'ok')
            {
                field_update(fieldName, makeFieldValue(response.data.data));
                formsFilesStorage(delId, 'del');
            }
        }
        catch (e) {
            //
        }

    }


    //Временное хранение id файлов
    const formsFilesStorage = (id, action = '') => {

        let formFilesStr = localStorage.getItem('form_files');
        let formFilesArr = formFilesStr.split(',');

        if(action === 'del')
        {
            const arrIndex = formFilesArr.indexOf(id);
            if (arrIndex !== -1)
            {
                formFilesArr.splice(arrIndex, 1);
            }
        }
        else
        {
            formFilesArr.push(id);
        }

        formFilesStr = formFilesArr.join(',');
        localStorage.setItem('form_files', formFilesStr);
    }


    //Значение файла
    const makeFieldValue = (data) => {
        const valueArr = [data.id, data.name];
        let valueStr = valueArr.join('|');

        if(valueStr === '|')
        {
            valueStr = '';
        }

        return valueStr;
    }


    //
    return (
        <div>

            <input
                type="hidden"
                name={name}
                value={value}
                onChange={onChange}
            />

            {
                value === ''
                    ?
                    <div>
                        {
                            isLoading ? <Preloader /> : <div></div>
                        }
                        <input
                            type="file"
                            onChange={ e => addFile(e, name) }
                            accept={accept}
                        />
                    </div>
                    :
                    <div>
                        <a href={fileUrl} download>просмотреть файл</a>
                        &nbsp; | &nbsp;
                        <a href="#" onClick={ e => delFile(e, name, valueArr[0]) }>удалить файл</a>
                    </div>
            }
        </div>
    );


};

export default FormInputFile;