import React from 'react';
import RulesItem from "./RulesItem";
import {dragContentHorizontal} from "../../../utils/DragContentHorizontal";

const RulesList = ({items, isLoading, func}) => {

    if (!items.length && ! isLoading) {
        return (
            <div>
                Записи не найдены!
            </div>
        )
    }

    return (
        <div className="data_table_div" onMouseDown={e => dragContentHorizontal(e)}>

            <table cellPadding="0" cellSpacing="0" border="0">
                <thead>
                <tr>
                    <td>ID</td>
                    <td>Наименование группы</td>
                    <td>Примечание</td>
                    <td>Действия</td>
                </tr>
                </thead>

                <tbody>
                {
                    items.map(
                        (item, index) =>
                            <RulesItem
                                key={item.id}
                                item={item}
                                func={func}
                            />
                    )
                }
                </tbody>
            </table>

        </div>
    );
};

export default RulesList;