import React from 'react';
import LogoutElem from "../../components/Pages/Login/LogoutElem";

const LogoutPage = () => {

    document.title = 'Выход - Документооборот по закупкам';

    return (
        <LogoutElem />
    );
};

export default LogoutPage;