import React from 'react';
import {searchInArray} from "../../../utils/SearchInArray";
import {RuleDel, RuleEdit} from "../../Interface/Rule/RuleAction";

const DepartmentsItem = ({item, func, rolesList}) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{searchInArray(rolesList, item.rule_id, 'id', 'name')}</td>
            <td>{item.note}</td>
            <td>
                <div className="action_link_icon_list">

                    <RuleEdit>
                        <a href="#"
                           className="action_link_icon action_link_icon_edit rule_edit"
                           title="Редактировать"
                           onClick={ e => func.edit(e, item.id) }
                        >&#xf044;</a>
                    </RuleEdit>

                    <RuleDel>
                        <a href="#"
                           className="action_link_icon action_link_icon_delete rule_del"
                           title="Удалить"
                           onClick={ e => func.del(e, item.id) }
                        >&#xf00d;</a>
                    </RuleDel>

                </div>
            </td>
        </tr>
    );
};

export default DepartmentsItem;