import React from 'react';
import {searchInArray} from "../../../../utils/SearchInArray";

const ActionsWidgetItem = ({item, func, references}) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{searchInArray(references.sectionsList, item.section, 'path', 'title')}</td>
            <td>{searchInArray(references.actionsList, item.action, 'value', 'name')}</td>
            <td>{item.time}</td>
        </tr>
    );
};

export default ActionsWidgetItem;