export const getNextElem = (torgId, torgList, key) => {

    //
    let nextTorgId = 1;

    if(torgId > 0)
    {
        //
        let searchIndex = 0;

        //
        for( var i = 0, len = torgList.length; i < len; i++ ) {
            if( torgList[i][key] == torgId ) {
                searchIndex = i;
                break;
            }
        }

        //
        if(searchIndex + 1 < torgList.length)
        {
            nextTorgId = torgList[searchIndex + 1][key];
        }
    }

    return nextTorgId;
}