import React from 'react';
import {RuleDel, RuleEdit} from "../../Interface/Rule/RuleAction";
import {searchInArray} from "../../../utils/SearchInArray";
import {dateFormat} from "../../../utils/DateFormat";


const CommissionsItem = ({item, func, references}) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{item.num}</td>
            <td>{item.chairman}</td>
            <td>{item.secretary}</td>
            <td>{item.member1}</td>
            <td>{item.member2}</td>
            <td>{searchInArray(references.actualsList, item.actual_status, 'value', 'name')}</td>
            <td>{ dateFormat(item.actual_date, 'd.m.Y') }</td>
            <td>
                <div className="action_link_icon_list">

                    <RuleEdit>
                        <a href="#"
                           className="action_link_icon action_link_icon_edit rule_edit"
                           title="Редактировать"
                           onClick={ e => func.edit(e, item.id) }
                        >&#xf044;</a>
                    </RuleEdit>

                    <RuleDel>
                        <a href="#"
                           className="action_link_icon action_link_icon_delete rule_del"
                           title="Удалить"
                           onClick={ e => func.del(e, item.id) }
                        >&#xf00d;</a>
                    </RuleDel>

                </div>
            </td>
        </tr>
    );
};

export default CommissionsItem;