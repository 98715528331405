export const switchClass = function (e, off, on, className, switchElem, switchClass) {

    //
    e.preventDefault();

    //
    const offElems = document.querySelectorAll(off);
    for (let i = 0; i < offElems.length; i++) {
        offElems[i].classList.add(className);
    }

    //
    const onElems = document.querySelectorAll(on);
    for (let i = 0; i < onElems.length; i++) {
        onElems[i].classList.remove(className);
    }

    //
    const switchElems = document.querySelectorAll(switchElem);
    for (let i = 0; i < switchElems.length; i++) {
        switchElems[i].classList.remove(switchClass);
    }

    //
    e.currentTarget.classList.add(switchClass);

};